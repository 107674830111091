// BotConfig.js

import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../contexts/CompanyContext';
import { Save, Send, Paperclip } from 'lucide-react'; // Importando os ícones Save, Send e Paperclip
import { auth } from '../firebase'; // Importar o auth do Firebase

const BotConfig = () => {
  const [assistantId, setAssistantId] = useState(''); // Estado para armazenar o ID do assistente
  const [name, setName] = useState('');
  const [instructions, setInstructions] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null); // Para feedback ao usuário
  const [updatedAt, setUpdatedAt] = useState(null);
  const { selectedCompany } = useContext(CompanyContext);

  // Novos estados para o chat
  const [chatMessages, setChatMessages] = useState([]); // Armazena as mensagens do chat
  const [userInput, setUserInput] = useState(''); // Armazena o texto digitado pelo usuário
  const [chatLoading, setChatLoading] = useState(false); // Controla o estado de carregamento do chat

  // Estado para o modo (produção ou desenvolvimento)
  const [isProduction, setIsProduction] = useState(false);

  // Defina a URL correta sem o caminho do endpoint
  const backendUrl = 'https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT';

  // Função para formatar a data no formato desejado
  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    let date;
    if (timestamp._seconds) {
      // Se for um timestamp do Firestore
      date = new Date(timestamp._seconds * 1000);
    } else if (timestamp.seconds) {
      // Outro formato de timestamp do Firestore
      date = new Date(timestamp.seconds * 1000);
    } else {
      // Se for uma string ou outro formato
      date = new Date(timestamp);
    }
    const day = date.getDate().toString().padStart(2, '0'); // Dia com zero à esquerda
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Mês com zero à esquerda
    const hours = date.getHours().toString().padStart(2, '0'); // Horas com zero à esquerda
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Minutos com zero à esquerda
    return `${day}/${month}, ${hours}:${minutes}`;
  };

  // UseEffect para buscar o assistantId e os dados do assistente ao montar o componente
  useEffect(() => {
    const fetchAssistantIdAndData = async () => {
      try {
        // Verifica se há uma empresa selecionada
        if (!selectedCompany?.companyId) {
          console.log("Nenhuma empresa selecionada, aguardando...");
          return;
        }
        const currentUser = auth.currentUser;
  
        if (!currentUser) {
          setMessage({ type: 'error', text: 'Usuário não autenticado. Por favor, faça login.' });
          return;
        }
  
        const authToken = await currentUser.getIdToken();
  
        // Inclui o companyId na URL para filtrar o assistente correto
        const idResponse = await fetch(
          `${backendUrl}/get_assistant_id?companyId=${selectedCompany.companyId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authToken}`,
            },
          }
        );
  
        if (idResponse.status === 404) {
          return;
        } else if (!idResponse.ok) {
          const errorText = await idResponse.text();
          throw new Error(errorText);
        }
  
        const idData = await idResponse.json();
        const ASSISTANT_ID = idData.assistant_id;
  
        if (!ASSISTANT_ID) {
          return;
        }
  
        setAssistantId(ASSISTANT_ID);
  
        // Buscar os dados do assistente usando o assistantId
        const response = await fetch(
          `${backendUrl}/get_assistant?assistant_id=${ASSISTANT_ID}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authToken}`,
            },
          }
        );
  
        if (response.status === 404) {
          setAssistantId('');
          return;
        } else if (!response.ok) {
          const errorText = await response.text();
          throw new Error(errorText);
        }
  
        const data = await response.json();
        const assistant = data.assistant;
  
        setName(assistant.name || '');
        setInstructions(assistant.instructions || '');
        setUpdatedAt(assistant.updatedAt || null);
  
      } catch (error) {
        console.error('Erro ao obter os dados do assistente:', error);
      }
    };
  
    fetchAssistantIdAndData();
  }, [selectedCompany]); // Reexecuta quando o selectedCompany muda  

  const handleSave = async () => {
    // Validação dos campos
    if (!name.trim() || !instructions.trim()) {
      setMessage({ type: 'error', text: 'Por favor, preencha todos os campos obrigatórios.' });
      return;
    }

    setLoading(true);
    setMessage(null);

    try {
      const currentUser = auth.currentUser;

      if (!currentUser) {
        setMessage({ type: 'error', text: 'Usuário não autenticado. Por favor, faça login.' });
        setLoading(false);
        return;
      }

      const authToken = await currentUser.getIdToken();

      const url = assistantId ? `${backendUrl}/update_assistant` : `${backendUrl}/create_assistant`;
      const method = 'POST';

      const body = {
        model: 'gpt-4o-mini',
        name,
        instructions,
        tools: [],
        companyId: selectedCompany?.companyId,
      };

      if (assistantId) {
        body.assistant_id = assistantId;
      }

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        const errorText = await response.text();
        let errorMessage = assistantId ? 'Erro ao atualizar o assistente.' : 'Erro ao criar o assistente.';
        try {
          const errorData = JSON.parse(errorText);
          if (typeof errorData.error === 'string') {
            errorMessage = errorData.error;
          } else if (typeof errorData.error === 'object') {
            errorMessage = errorData.error.message || errorMessage;
          }
        } catch (e) {
          console.error('Erro ao parsear resposta de erro:', e);
          console.error('Texto de erro:', errorText);
        }
        throw new Error(errorMessage);
      }

      const data = await response.json();
      console.log('Assistente salvo:', data);
      setMessage({ type: 'success', text: 'Assistente salvo com sucesso!' });

      // Atualizar o assistantId se for um novo assistente
      if (!assistantId && data.assistant && data.assistant.id) {
        setAssistantId(data.assistant.id);
      }

      // Atualizar o updatedAt com o valor recebido
      setUpdatedAt(data.assistant.updatedAt || null);

    } catch (error) {
      console.error('Erro:', error);
      let errorMessage = error.message;
      if (typeof errorMessage === 'object') {
        errorMessage = JSON.stringify(errorMessage);
      }
      setMessage({ type: 'error', text: errorMessage });
    } finally {
      setLoading(false);
    }
  };

  // Função para enviar mensagem no chat
  const handleSendMessage = async () => {
    if (!userInput.trim()) return;

    // Adicionar a mensagem do usuário imediatamente
    const userMessage = { sender: 'user', text: userInput };
    setChatMessages((prevMessages) => [...prevMessages, userMessage]);
    setUserInput('');
    setChatLoading(true);

    try {
      const currentUser = auth.currentUser;

      if (!currentUser) {
        setMessage({ type: 'error', text: 'Usuário não autenticado. Por favor, faça login.' });
        setChatLoading(false);
        return;
      }

      const authToken = await currentUser.getIdToken();

      // Enviar a mensagem para o backend
      const response = await fetch(`${backendUrl}/chat_with_assistant`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          userInput: userInput,
          assistant_id: assistantId,
          mode: isProduction ? 'production' : 'development', // Enviar o modo atual
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const data = await response.json();
      const assistantResponse = data.message;

      // Adicionar a resposta do assistente
      const botMessage = { sender: 'assistant', text: assistantResponse };
      setChatMessages((prevMessages) => [...prevMessages, botMessage]);

    } catch (error) {
      console.error('Erro ao enviar mensagem no chat:', error);
      setMessage({ type: 'error', text: 'Erro ao enviar mensagem no chat.' });
    } finally {
      setChatLoading(false);
    }
  };

  // Função para lidar com o anexo
  const handleAttachment = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Lógica para lidar com o upload do arquivo
      console.log('Arquivo anexado:', file);
      // Implementar upload conforme a necessidade
    }
  };

  // Função para alternar o modo
  const toggleMode = () => {
    setIsProduction((prevMode) => !prevMode);
    // Implementar lógica adicional conforme necessário
  };

  return (
    <div
      className="bot-config-container"
      style={{
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#242424',
        color: '#fff',
        height: '100vh',
        width: '100vw',
        padding: '20px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      {/* Conteúdo Principal com os Dois Blocos */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: '40px',
          flex: 1,
        }}
      >
        {/* Formulário de Configuração */}
        <div
          style={{
            backgroundColor: '#242424',
            color: '#fff',
            padding: '30px',
            borderRadius: '12px',
            width: '50%',
            height: '100%',
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <div style={{ marginBottom: '20px' }}>
              <label htmlFor="name" style={{ display: 'block', marginBottom: '10px', fontSize: '18px' }}>
                Nome
              </label>
              <input
                id="name"
                type="text"
                style={{
                  backgroundColor: '#333',
                  color: '#fff',
                  padding: '12px',
                  borderRadius: '6px',
                  border: '1px solid #555',
                  width: '100%',
                  fontSize: '16px',
                }}
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nome do assistente"
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <label htmlFor="instructions" style={{ display: 'block', marginBottom: '10px', fontSize: '18px' }}>
                Instruções
              </label>
              <textarea
                id="instructions"
                style={{
                  backgroundColor: '#333',
                  color: '#fff',
                  padding: '12px',
                  borderRadius: '6px',
                  border: '1px solid #555',
                  width: '100%',
                  height: '280px', // Aumentado para utilizar mais espaço
                  fontSize: '16px',
                  resize: 'vertical', // Permite redimensionar verticalmente
                }}
                value={instructions}
                onChange={(e) => setInstructions(e.target.value)}
                placeholder="Instruções para o assistente"
              ></textarea>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {/* Exibir data de atualização */}
            {updatedAt && (
              <div style={{ fontSize: '14px', color: '#e5e7eb' }}>
                Atualizado {formatDate(updatedAt)}
              </div>
            )}
            <button
              style={{
                backgroundColor: '#242424',
                padding: '8px 16px',
                color: '#e5e7eb',
                borderRadius: '6px',
                cursor: 'pointer',
                border: '1px solid #555',
                fontSize: '16px',
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                transition: 'background-color 0.3s',
              }}
              onClick={handleSave}
              disabled={loading} // Desabilitar o botão enquanto carrega
            >
              <Save size={16} />
              {loading ? 'Salvando...' : assistantId ? 'Atualizar' : 'Salvar'}
            </button>
          </div>
          {message && (
            <div
              style={{
                marginTop: '10px',
                color: message.type === 'error' ? '#ff6b6b' : '#4caf50',
                fontSize: '16px',
              }}
            >
              {message.text}
            </div>
          )}
        </div>

        {/* Pré-visualização do Chatbot */}
        <div
          style={{
            backgroundColor: '#242424',
            color: '#fff',
            padding: '30px',
            borderRadius: '12px',
            width: '50%',
            height: '100%',
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          {/* Botão de Modo de Produção/Desenvolvimento */}
          <div style={{ marginBottom: '20px' }}>
            <label htmlFor="mode-toggle" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <span style={{ marginRight: '10px', fontSize: '16px' }}>Modo de Produção</span>
              <div style={{ position: 'relative', width: '50px', height: '24px' }}>
                <input
                  id="mode-toggle"
                  type="checkbox"
                  checked={isProduction}
                  onChange={toggleMode}
                  style={{ display: 'none' }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: isProduction ? '#4caf50' : '#ccc',
                    borderRadius: '34px',
                    transition: 'background-color 0.2s',
                  }}
                ></div>
                <div
                  style={{
                    position: 'absolute',
                    top: '2px',
                    left: isProduction ? '26px' : '2px',
                    width: '20px',
                    height: '20px',
                    backgroundColor: '#fff',
                    borderRadius: '50%',
                    transition: 'left 0.2s',
                  }}
                ></div>
              </div>
            </label>
          </div>

          {/* Área de Mensagens */}
          <div
            style={{
              backgroundColor: '#333',
              flexGrow: 1,
              overflowY: 'auto',
              padding: '20px',
              borderRadius: '6px',
              border: '1px solid #555',
            }}
          >
            {chatMessages.map((msg, index) => (
              <div key={index} style={{ marginBottom: '10px' }}>
                <div
                  style={{
                    textAlign: msg.sender === 'user' ? 'right' : 'left',
                  }}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      backgroundColor: msg.sender === 'user' ? '#242424' : '#555', // Balão do usuário em #242424
                      color: '#fff',
                      padding: '10px',
                      borderRadius: '6px',
                      maxWidth: '70%',
                      wordWrap: 'break-word',
                    }}
                  >
                    {msg.text}
                  </span>
                </div>
              </div>
            ))}
            {/* Removido o balão "Respondendo..." */}
          </div>

          {/* Área de Envio de Mensagens */}
          <div style={{ marginTop: '30px', display: 'flex', alignItems: 'center' }}>
            {/* Ícone de Anexo */}
            <label htmlFor="attachment" style={{ cursor: 'pointer', marginRight: '10px' }}>
              <Paperclip size={20} />
              <input
                id="attachment"
                type="file"
                style={{ display: 'none' }}
                onChange={handleAttachment}
              />
            </label>

            {/* Campo de Entrada de Texto */}
            <input
              type="text"
              placeholder="Digite uma mensagem..."
              style={{
                backgroundColor: '#242424',
                color: '#e5e7eb',
                padding: '12px',
                borderRadius: '6px',
                border: '1px solid #555',
                flex: 1,
                fontSize: '16px',
                outline: 'none',
              }}
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
            />

            {/* Botão de Envio */}
            <button
              style={{
                marginLeft: '10px',
                backgroundColor: '#242424',
                border: 'none',
                cursor: 'pointer',
                color: '#e5e7eb',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
                borderRadius: '6px',
                transition: 'background-color 0.3s',
              }}
              aria-label="Enviar mensagem"
              onClick={handleSendMessage}
              disabled={chatLoading}
            >
              <Send size={20} />
            </button>
          </div>
        </div>
      </div>

      {/* Media Query para telas menores */}
      <style>
        {`
          @media (max-width: 768px) {
            .bot-config-container > div:nth-child(2) {
              flex-direction: column;
              gap: 20px;
            }

            .bot-config-container > div:nth-child(2) > div {
              width: 100% !important;
            }
          }

          /* Alterar a cor do placeholder */
          input::placeholder {
            color: #cbd5e1; /* placeholder-gray-400 */
          }

          /* Adicionar hover nos botões */
          button:hover {
            background-color: #555555;
          }
        `}
      </style>
    </div>
  );
};

export default BotConfig;
