import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { X } from 'lucide-react';

const CompanyItem = ({ company, onSelect }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <li
      onClick={() => onSelect(company)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        cursor: 'pointer',
        padding: '16px',
        border: '1px solid #444654',
        borderRadius: '8px',
        marginBottom: '12px',
        color: '#fff',
        backgroundColor: isHovered ? '#3a3a3a' : 'transparent',
        transition: 'background-color 0.3s'
      }}
    >
      {company.companyName || 'Empresa sem nome'}
    </li>
  );
};

CompanyItem.propTypes = {
  company: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const CompanySelectionModal = ({ companies, onSelect, onClose }) => {
  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
      overflowY: 'auto'
    }}>
      <div style={{
        backgroundColor: '#242424',
        padding: '20px',
        borderRadius: '12px',
        width: '90%',
        maxWidth: '400px',
        maxHeight: '90vh',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden'
      }}>
        <button
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '16px',
            right: '16px',
            background: 'none',
            border: 'none',
            color: '#fff',
            cursor: 'pointer'
          }}
          aria-label="Fechar"
        >
          <X size={24} />
        </button>
        <h2 style={{
          textAlign: 'center',
          color: '#fff',
          marginBottom: '20px',
          fontSize: '1.25rem',
          fontWeight: 'bold'
        }}>
          Selecione uma Empresa
        </h2>
        <ul style={{ listStyle: 'none', padding: 0 }}>
          {companies.map((company) => (
            <CompanyItem key={company.companyId} company={company} onSelect={onSelect} />
          ))}
        </ul>
      </div>
    </div>
  );
};

CompanySelectionModal.propTypes = {
  companies: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CompanySelectionModal;
