import React, { useState, useEffect, useContext } from 'react';
import { getAuth } from 'firebase/auth';
import { CompanyContext } from '../contexts/CompanyContext';
import { Lightbulb, X, Upload, Plus, Trash2, Save, CheckCircle, AlertCircle, MapPin, Mail, Globe, Building2, Phone, Info, MessageSquare } from 'lucide-react';

const WhatsAppProfile = ({ onBack }) => {
  const { selectedCompany } = useContext(CompanyContext);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [hasActiveNumber, setHasActiveNumber] = useState(false);
  const [profile, setProfile] = useState({
    displayName: '',
    phoneNumber: '',
    about: '',
    address: '',
    email: '',
    websites: [''],
    category: '',
    profilePicture: null,
    status: '',
  });

  const [charCount, setCharCount] = useState({
    about: 0,
    address: 0,
    email: 0,
    website: 0,
    status: 0,
  });

  const categories = [
    'Alimentos e Mercearia',
    'Apostas e Jogos Online',
    'Automotivo',
    'Bebidas Alcoólicas',
    'Beleza, Spa e Salão',
    'Educação',
    'Entretenimento',
    'Finanças e Bancos',
    'Governo/Setor Público',
    'Hotelaria e Hospedagem',
    'Medicamentos OTC (sem receita)',
    'Moda e Vestuário',
    'Não é uma empresa',
    'Organizações Sem Fins Lucrativos',
    'Outro',
    'Planejamento de Eventos',
    'Restaurantes',
    'Saúde e Medicina',
    'Serviços Profissionais',
    'Varejo',
    'Viagens e Transporte'
  ];

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const configData = await fetchWhatsAppConfig();
        if (!configData || !configData.numeroConectado) {
          setHasActiveNumber(false);
          return;
        }
        setHasActiveNumber(true);
        await fetchWhatsAppProfile();
      } catch (error) {
        console.error('Erro ao carregar dados:', error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedCompany?.companyId) {
      loadData();
    }
  }, [selectedCompany?.companyId]);

  const fetchWhatsAppConfig = async () => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const companyId = selectedCompany?.companyId;

      if (!companyId) {
        setMessage({ type: 'error', text: 'Empresa não selecionada.' });
        return null;
      }

      const response = await fetch(
        `https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/settings?companyId=${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      if (response.ok && data.whatsappConfig) {
        setProfile(prev => ({
          ...prev,
          displayName: data.whatsappConfig.nome || '',
          phoneNumber: data.whatsappConfig.numeroConectado || '',
        }));
        return data.whatsappConfig;
      } else {
        setMessage({ type: 'error', text: data.error || 'Erro ao carregar configurações.' });
        return null;
      }
    } catch (error) {
      console.error('Erro ao buscar configurações do WhatsApp:', error);
      setMessage({ type: 'error', text: 'Erro ao carregar configurações.' });
      return null;
    }
  };

  const fetchWhatsAppProfile = async () => {
    try {
      setLoading(true);
      setMessage(null); // Limpa mensagens anteriores
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const companyId = selectedCompany?.companyId;

      if (!companyId) {
        setMessage({ type: 'error', text: 'Empresa não selecionada.' });
        return;
      }

      const response = await fetch(
        `https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/whatsapp/profile?companyId=${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      
      if (response.ok && data.profile) {
        // Converte o website em array de websites se necessário
        const profileData = {
          ...data.profile,
          websites: Array.isArray(data.profile.websites) ? data.profile.websites : [data.profile.website || '']
        };
        setProfile(profileData);
        
        // Atualiza contadores de caracteres
        setCharCount({
          about: profileData.about?.length || 0,
          address: profileData.address?.length || 0,
          email: profileData.email?.length || 0,
          website: profileData.websites?.[0]?.length || 0,
          status: profileData.status?.length || 0,
        });
      } else {
        setMessage({ type: 'error', text: data.error || 'Erro ao carregar perfil.' });
      }
    } catch (error) {
      console.error('Erro ao buscar perfil:', error);
      setMessage({ type: 'error', text: 'Erro ao carregar perfil. Tente novamente.' });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Atualiza contador de caracteres para todos os campos relevantes
    if (['about', 'address', 'email', 'status'].includes(name)) {
      setCharCount(prev => ({
        ...prev,
        [name]: value.length
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setMessage(null); // Limpa mensagens anteriores
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const companyId = selectedCompany?.companyId;

      if (!companyId) {
        setMessage({ type: 'error', text: 'Empresa não selecionada.' });
        return;
      }

      // Validações de caracteres
      if (profile.about && profile.about.length > 512) {
        setMessage({ type: 'error', text: 'A descrição não pode ter mais que 512 caracteres.' });
        return;
      }
      if (profile.address && profile.address.length > 256) {
        setMessage({ type: 'error', text: 'O endereço não pode ter mais que 256 caracteres.' });
        return;
      }
      if (profile.email && profile.email.length > 128) {
        setMessage({ type: 'error', text: 'O email não pode ter mais que 128 caracteres.' });
        return;
      }
      if (profile.status && profile.status.length > 130) {
        setMessage({ type: 'error', text: 'O recado não pode ter mais que 130 caracteres.' });
        return;
      }
      if (profile.websites.some(website => website && website.length > 256)) {
        setMessage({ type: 'error', text: 'Os websites não podem ter mais que 256 caracteres.' });
        return;
      }

      const formData = new FormData();
      Object.keys(profile).forEach(key => {
        if (key === 'profilePicture' && profile[key]) {
          formData.append('profilePicture', profile[key]);
        } else if (key === 'websites') {
          formData.append('websites', JSON.stringify(profile[key]));
        } else if (profile[key] !== null && profile[key] !== undefined) {
          formData.append(key, profile[key]);
        }
      });

      const response = await fetch(
        `https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/whatsapp/profile?companyId=${companyId}`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      const data = await response.json();
      
      if (response.ok) {
        setMessage({ type: 'success', text: 'Perfil atualizado com sucesso!' });
        // Atualiza o perfil local após salvar com sucesso
        await fetchWhatsAppProfile();
      } else {
        setMessage({ type: 'error', text: data.error || 'Erro ao atualizar perfil.' });
        // Mantém os dados atuais na tela em caso de erro
        console.error('Erro na resposta:', data);
      }
    } catch (error) {
      console.error('Erro ao atualizar perfil:', error);
      setMessage({ type: 'error', text: 'Erro ao atualizar perfil. Tente novamente.' });
      // Mantém os dados atuais na tela em caso de erro
    } finally {
      setLoading(false);
    }
  };

  const handleWebsiteChange = (index, value) => {
    setProfile((prev) => ({
      ...prev,
      websites: prev.websites.map((website, i) => (i === index ? value : website)),
    }));

    // Atualiza contador de caracteres do website
    setCharCount(prev => ({
      ...prev,
      website: value.length
    }));
  };

  const addWebsite = () => {
    if (profile.websites.length < 2) {
      setProfile((prev) => ({
        ...prev,
        websites: [...prev.websites, ''],
      }));
    }
  };

  const removeWebsite = (index) => {
    setProfile((prev) => ({
      ...prev,
      websites: prev.websites.filter((_, i) => i !== index),
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfile((prev) => ({
        ...prev,
        profilePicture: file,
      }));
    }
  };

  return (
    <div
      style={{
        backgroundColor: '#242424',
        color: '#fff',
        padding: '30px',
        borderRadius: '12px',
        width: '100%',
        maxWidth: '800px',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        margin: '0 auto',
        position: 'relative',
        maxHeight: '90vh',
        overflowY: 'auto',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '20px' }}>
      </div>

      {message && (
        <div
          style={{
            position: 'fixed',
            top: '20px',
            right: '20px',
            padding: '15px 25px',
            backgroundColor: message.type === 'success' ? '#4CAF50' : '#f44336',
            color: '#fff',
            borderRadius: '6px',
            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
            zIndex: 1000,
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            fontSize: '14px',
            maxWidth: '400px',
            textAlign: 'left',
          }}
        >
          {message.type === 'success' ? <CheckCircle size={20} /> : <AlertCircle size={20} />}
          {message.text}
        </div>
      )}

      {!hasActiveNumber && (
        <div style={{ 
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#242424',
          padding: '30px',
          borderRadius: '12px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.5)',
          zIndex: 1000,
          width: '90%',
          maxWidth: '500px',
          textAlign: 'center',
          border: '1px solid #555'
        }}>
          <h3 style={{ fontSize: '20px', margin: '0 0 10px 0' }}>Número do WhatsApp não configurado</h3>
          <p style={{ color: '#aaa', margin: '0 0 20px 0' }}>
            Para editar o perfil do WhatsApp, você precisa primeiro adicionar um número na tela de configurações.
          </p>
          <button
            onClick={onBack}
            style={{
              backgroundColor: '#333',
              color: '#fff',
              padding: '12px 24px',
              border: '1px solid #555',
              borderRadius: '6px',
              cursor: 'pointer',
              fontSize: '16px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              margin: '0 auto',
            }}
          >
            Voltar para Configurações
          </button>
        </div>
      )}

      <div style={{ 
        display: 'flex', 
        gap: '20px',
        opacity: hasActiveNumber ? 1 : 0.3,
        pointerEvents: hasActiveNumber ? 'auto' : 'none'
      }}>
        {/* Coluna da esquerda - Formulário */}
        <div style={{ flex: '1', display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            {/* Foto de Perfil */}
            <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
              <div style={{ flex: '1 1 100%', minWidth: '200px' }}>
                <label htmlFor="profilePicture" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                  Foto de Perfil
                </label>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                  <input
                    type="file"
                    id="profilePicture"
                    name="profilePicture"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: 'none' }}
                  />
                  <label
                    htmlFor="profilePicture"
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '12px 24px',
                      borderRadius: '6px',
                      border: '1px solid #555',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      fontSize: '14px',
                    }}
                  >
                    <Upload size={20} />
                    {profile.profilePicture ? 'Alterar Foto' : 'Selecionar Foto'}
                  </label>
                  {profile.profilePicture && (
                    <span style={{ fontSize: '14px', color: '#aaa' }}>
                      {profile.profilePicture.name}
                    </span>
                  )}
                </div>
              </div>
            </div>

            {/* Nome de Exibição (somente leitura) */}
            <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
              <div style={{ flex: '1 1 100%', minWidth: '200px' }}>
                <label htmlFor="displayName" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                  Nome de Exibição
                </label>
                <input
                  type="text"
                  id="displayName"
                  value={profile.displayName}
                  disabled
                  style={{
                    backgroundColor: '#333',
                    color: '#fff',
                    padding: '12px',
                    borderRadius: '6px',
                    border: '1px solid #555',
                    width: '100%',
                    fontSize: '14px',
                    boxSizing: 'border-box',
                    height: '44px',
                    cursor: 'not-allowed',
                  }}
                />
              </div>
            </div>

            {/* Número Conectado (somente leitura) */}
            <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
              <div style={{ flex: '1 1 100%', minWidth: '200px' }}>
                <label htmlFor="phoneNumber" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                  Número Conectado
                </label>
                <input
                  type="text"
                  id="phoneNumber"
                  value={profile.phoneNumber}
                  disabled
                  style={{
                    backgroundColor: '#333',
                    color: '#fff',
                    padding: '12px',
                    borderRadius: '6px',
                    border: '1px solid #555',
                    width: '100%',
                    fontSize: '14px',
                    boxSizing: 'border-box',
                    height: '44px',
                    cursor: 'not-allowed',
                  }}
                />
              </div>
            </div>

            {/* Categoria */}
            <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
              <div style={{ flex: '1 1 100%', minWidth: '200px' }}>
                <label htmlFor="category" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                  Categoria
                </label>
                <select
                  id="category"
                  name="category"
                  value={profile.category}
                  onChange={handleChange}
                  style={{
                    backgroundColor: '#333',
                    color: '#fff',
                    padding: '12px',
                    borderRadius: '6px',
                    border: '1px solid #555',
                    width: '100%',
                    fontSize: '14px',
                    boxSizing: 'border-box',
                    height: '44px',
                    cursor: 'pointer',
                  }}
                >
                  <option value="">Selecione uma categoria</option>
                  {categories.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Recado */}
            <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
              <div style={{ flex: '1 1 100%', minWidth: '200px' }}>
                <label htmlFor="status" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                  Recado
                </label>
                <div style={{ position: 'relative' }}>
                  <input
                    type="text"
                    id="status"
                    name="status"
                    value={profile.status}
                    onChange={handleChange}
                    maxLength={130}
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '12px',
                      borderRadius: '6px',
                      border: '1px solid #555',
                      width: '100%',
                      fontSize: '14px',
                      boxSizing: 'border-box',
                      height: '44px',
                      paddingRight: '60px',
                    }}
                  />
                  <div style={{ position: 'absolute', right: '12px', top: '50%', transform: 'translateY(-50%)', fontSize: '12px', color: '#aaa' }}>
                    {charCount.status}/130
                  </div>
                </div>
                <div style={{ fontSize: '12px', color: '#aaa', marginTop: '4px' }}>
                  Use o campo "Recado" para exibir uma mensagem de apresentação quando alguém encontrar seu contato no WhatsApp (opcional)
                </div>
              </div>
            </div>

            {/* Descrição */}
            <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
              <div style={{ flex: '1 1 100%', minWidth: '200px' }}>
                <label htmlFor="about" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                  Descrição
                </label>
                <div style={{ position: 'relative' }}>
                  <textarea
                    id="about"
                    name="about"
                    value={profile.about}
                    onChange={handleChange}
                    maxLength={512}
                    rows={3}
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '12px',
                      borderRadius: '6px',
                      border: '1px solid #555',
                      width: '100%',
                      fontSize: '14px',
                      boxSizing: 'border-box',
                      resize: 'vertical',
                      paddingBottom: '30px',
                    }}
                  />
                  <div style={{ position: 'absolute', bottom: '8px', right: '8px', fontSize: '12px', color: '#aaa' }}>
                    {charCount.about}/512
                  </div>
                </div>
                <div style={{ fontSize: '12px', color: '#aaa', marginTop: '4px' }}>
                  Inclua uma breve apresentação da sua empresa, destacando o que ela oferece e qual é a sua especialidade (opcional)
                </div>
              </div>
            </div>

            {/* Endereço */}
            <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
              <div style={{ flex: '1 1 100%', minWidth: '200px' }}>
                <label htmlFor="address" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                  Endereço
                </label>
                <div style={{ position: 'relative' }}>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={profile.address}
                    onChange={handleChange}
                    maxLength={256}
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '12px',
                      borderRadius: '6px',
                      border: '1px solid #555',
                      width: '100%',
                      fontSize: '14px',
                      boxSizing: 'border-box',
                      height: '44px',
                      paddingRight: '60px',
                    }}
                  />
                  <div style={{ position: 'absolute', right: '12px', top: '50%', transform: 'translateY(-50%)', fontSize: '12px', color: '#aaa' }}>
                    {charCount.address}/256
                  </div>
                </div>
                <div style={{ fontSize: '12px', color: '#aaa', marginTop: '4px' }}>
                  Se sua empresa possui um endereço físico, informe-o aqui para facilitar que seus clientes encontrem você rapidamente (opcional)
                </div>
              </div>
            </div>

            {/* E-mail */}
            <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
              <div style={{ flex: '1 1 100%', minWidth: '200px' }}>
                <label htmlFor="email" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                  Email
                </label>
                <div style={{ position: 'relative' }}>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={profile.email}
                    onChange={handleChange}
                    maxLength={128}
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '12px',
                      borderRadius: '6px',
                      border: '1px solid #555',
                      width: '100%',
                      fontSize: '14px',
                      boxSizing: 'border-box',
                      height: '44px',
                      paddingRight: '60px',
                    }}
                  />
                  <div style={{ position: 'absolute', right: '12px', top: '50%', transform: 'translateY(-50%)', fontSize: '12px', color: '#aaa' }}>
                    {charCount.email}/128
                  </div>
                </div>
                <div style={{ fontSize: '12px', color: '#aaa', marginTop: '4px' }}>
                  Informe um endereço de e-mail para que seus clientes possam entrar em contato facilmente com sua empresa (opcional)
                </div>
              </div>
            </div>

            {/* Websites */}
            <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
              <div style={{ flex: '1 1 100%', minWidth: '200px' }}>
                <label style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                  Site
                </label>
                {profile.websites.map((website, index) => (
                  <div key={index} style={{ display: 'flex', gap: '10px', marginBottom: '10px', position: 'relative' }}>
                    <div style={{ position: 'relative', flex: 1 }}>
                      <input
                        type="url"
                        value={website}
                        onChange={(e) => handleWebsiteChange(index, e.target.value)}
                        maxLength={256}
                        placeholder="https://"
                        style={{
                          backgroundColor: '#333',
                          color: '#fff',
                          padding: '12px',
                          borderRadius: '6px',
                          border: '1px solid #555',
                          width: '100%',
                          fontSize: '14px',
                          boxSizing: 'border-box',
                          height: '44px',
                          paddingRight: '60px',
                        }}
                      />
                      <div style={{ position: 'absolute', right: '12px', top: '50%', transform: 'translateY(-50%)', fontSize: '12px', color: '#aaa' }}>
                        {charCount.website}/256
                      </div>
                    </div>
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => removeWebsite(index)}
                        style={{
                          backgroundColor: '#333',
                          color: '#ff6b6b',
                          padding: '0 12px',
                          borderRadius: '6px',
                          border: '1px solid #555',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          height: '44px',
                        }}
                      >
                        <Trash2 size={20} />
                      </button>
                    )}
                  </div>
                ))}
                {profile.websites.length < 2 && (
                  <button
                    type="button"
                    onClick={addWebsite}
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '12px',
                      borderRadius: '6px',
                      border: '1px solid #555',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      fontSize: '14px',
                      marginTop: '10px',
                    }}
                  >
                    <Plus size={20} />
                    Adicionar Website
                  </button>
                )}
                <div style={{ fontSize: '12px', color: '#aaa', marginTop: '4px' }}>
                  Adicione até dois sites que seus clientes poderão acessar diretamente ao visualizar seu perfil no WhatsApp (opcional)
                </div>
              </div>
            </div>
          </form>
        </div>

        {/* Coluna da direita - Preview */}
        <div style={{ flex: '1', backgroundColor: '#333', borderRadius: '12px', padding: '20px', maxHeight: '400px', overflowY: 'auto' }}>
          <h3 style={{ margin: '0 0 20px 0', fontSize: '16px', textAlign: 'center' }}>Pré-visualizar</h3>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'center' }}>
            {/* Foto de Perfil */}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
              <div style={{ width: '60px', height: '60px', borderRadius: '50%', backgroundColor: '#444', overflow: 'hidden' }}>
                {profile.profilePicture && profile.profilePicture instanceof File && (
                  <img
                    src={URL.createObjectURL(profile.profilePicture)}
                    alt="Foto de perfil"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                )}
                {profile.profilePicture && typeof profile.profilePicture === 'string' && (
                  <img
                    src={profile.profilePicture}
                    alt="Foto de perfil"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                )}
              </div>
              <div style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{profile.displayName}</div>
                <div style={{ fontSize: '12px', color: '#aaa' }}>{profile.phoneNumber}</div>
              </div>
            </div>

            {/* Recado */}
            {profile.status && (
              <div style={{ display: 'flex', alignItems: 'flex-start', gap: '8px', width: '100%', padding: '0 20px' }}>
                <MessageSquare size={16} color="#aaa" />
                <div style={{ fontSize: '12px', textAlign: 'left' }}>{profile.status}</div>
              </div>
            )}

            {/* Categoria */}
            {profile.category && (
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px', width: '100%', padding: '0 20px' }}>
                <Building2 size={16} color="#aaa" />
                <div style={{ fontSize: '12px', textAlign: 'left' }}>{profile.category}</div>
              </div>
            )}

            {/* Descrição */}
            {profile.about && (
              <div style={{ display: 'flex', alignItems: 'flex-start', gap: '8px', width: '100%', padding: '0 20px' }}>
                <Info size={16} color="#aaa" />
                <div style={{ fontSize: '12px', textAlign: 'left' }}>{profile.about}</div>
              </div>
            )}

            {/* Endereço */}
            {profile.address && (
              <div style={{ display: 'flex', alignItems: 'flex-start', gap: '8px', width: '100%', padding: '0 20px' }}>
                <MapPin size={16} color="#aaa" />
                <div style={{ fontSize: '12px', textAlign: 'left' }}>{profile.address}</div>
              </div>
            )}

            {/* Contatos */}
            {(profile.email || profile.websites.some(w => w)) && (
              <div style={{ width: '100%', padding: '0 20px' }}>
                <div style={{ fontSize: '12px', color: '#aaa', marginBottom: '5px' }}>Contatos</div>
                {profile.email && (
                  <div style={{ fontSize: '12px', marginBottom: '5px', display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Mail size={16} color="#aaa" />
                    {profile.email}
                  </div>
                )}
                {profile.websites.map((website, index) => (
                  website && (
                    <div key={index} style={{ fontSize: '12px', marginBottom: '5px', display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <Globe size={16} color="#aaa" />
                      <a href={website} target="_blank" rel="noopener noreferrer" style={{ color: '#aaa', textDecoration: 'none' }}>
                        {website}
                      </a>
                    </div>
                  )
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
        <button
          type="submit"
          disabled={loading}
          onClick={handleSubmit}
          style={{
            backgroundColor: '#333',
            color: '#fff',
            padding: '12px 24px',
            border: '1px solid #555',
            borderRadius: '6px',
            cursor: loading ? 'not-allowed' : 'pointer',
            fontSize: '16px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            opacity: loading ? 0.7 : 1,
          }}
        >
          {loading ? (
            <>
              <Lightbulb size={20} className="blinking-icon" />
              Salvando...
            </>
          ) : (
            <>
              <Save size={20} />
              Salvar
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default WhatsAppProfile; 