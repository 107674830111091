// MessageDispatcher.js

import React, { useState, useEffect, useContext } from 'react';
import { Send, Plus, X } from 'lucide-react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CompanyContext } from '../contexts/CompanyContext';

const MessageDispatcher = ({ onBack }) => {
  const { selectedCompany } = useContext(CompanyContext);
  // Estados para Histórico de Disparos
  const [dispatchHistory, setDispatchHistory] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(true);
  const [historyError, setHistoryError] = useState(null);

  // Estado para Busca
  const [searchQuery, setSearchQuery] = useState('');

  // Estados para Criação de Disparo
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [messageContent, setMessageContent] = useState('');
  const [scheduledDate, setScheduledDate] = useState(null);
  const [createLoading, setCreateLoading] = useState(false);
  const [createMessage, setCreateMessage] = useState(null);

  // Estados para Visualização de Detalhes do Disparo
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [selectedDispatch, setSelectedDispatch] = useState(null);

  // Dados fictícios para testes
  const fictitiousContact = { id: 'c1', name: 'Contato Fictício', phone: '(11) 91234-5678' };
  const initialDispatchHistory = [
    {
      id: 'd1',
      name: 'Campanha de Abril',
      scheduledTime: '2024-04-25T14:30:00',
      quantity: 1,
      contacts: [fictitiousContact],
      message: 'Mensagem de teste para Campanha de Abril.',
    },
    {
      id: 'd2',
      name: 'Promoção de Maio',
      scheduledTime: '2024-05-10T09:00:00',
      quantity: 1,
      contacts: [fictitiousContact],
      message: 'Mensagem de teste para Promoção de Maio.',
    },
  ];

  // Inicializar histórico fictício
  useEffect(() => {
    // Simular chamada à API
    const fetchDispatchHistory = async () => {
      setHistoryLoading(true);
      try {
        // Simular atraso
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setDispatchHistory(initialDispatchHistory);
      } catch (error) {
        setHistoryError('Erro ao carregar histórico.');
      } finally {
        setHistoryLoading(false);
      }
    };

    fetchDispatchHistory();
  }, []);

  // Função para abrir a modal de criação
  const openCreateModal = () => {
    setIsModalOpen(true);
    setSelectedContacts([]);
    setMessageContent('');
    setScheduledDate(null);
    setCreateMessage(null);
  };

  // Função para fechar a modal de criação
  const closeCreateModal = () => {
    setIsModalOpen(false);
    setSelectedContacts([]);
    setMessageContent('');
    setScheduledDate(null);
    setCreateMessage(null);
  };

  // Função para selecionar/deselecionar contatos
  const handleContactSelection = (contact) => {
    if (selectedContacts.find((c) => c.id === contact.id)) {
      setSelectedContacts(selectedContacts.filter((c) => c.id !== contact.id));
    } else {
      setSelectedContacts([...selectedContacts, contact]);
    }
  };

  // Função para enviar um novo disparo
  const handleSendMessage = async () => {
    if (selectedContacts.length === 0) {
      setCreateMessage({ type: 'error', text: 'Selecione pelo menos um contato.' });
      return;
    }

    if (!messageContent.trim()) {
      setCreateMessage({ type: 'error', text: 'A mensagem não pode estar vazia.' });
      return;
    }

    if (!scheduledDate) {
      setCreateMessage({ type: 'error', text: 'Selecione a data e hora para o envio.' });
      return;
    }

    setCreateLoading(true);
    setCreateMessage(null);

    try {
      // Simular chamada à API para criar o disparo
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Atualizar o histórico com o novo disparo
      const newDispatch = {
        id: `d${dispatchHistory.length + 1}`,
        name: `Disparo ${dispatchHistory.length + 1}`,
        scheduledTime: scheduledDate.toISOString(),
        quantity: selectedContacts.length,
        contacts: selectedContacts,
        message: messageContent,
        companyId: selectedCompany?.companyId,
      };
      setDispatchHistory([...dispatchHistory, newDispatch]);

      setCreateMessage({ type: 'success', text: 'Mensagem agendada com sucesso!' });

      // Fechar a modal após um breve delay para mostrar a mensagem de sucesso
      setTimeout(() => {
        closeCreateModal();
      }, 1500);
    } catch (error) {
      console.error('Erro ao agendar mensagem:', error);
      setCreateMessage({ type: 'error', text: 'Erro ao agendar mensagem. Tente novamente.' });
    } finally {
      setCreateLoading(false);
    }
  };

  // Função para abrir a modal de detalhes do disparo
  const openDetailModal = (dispatch) => {
    setSelectedDispatch(dispatch);
    setIsDetailModalOpen(true);
  };

  // Função para fechar a modal de detalhes
  const closeDetailModal = () => {
    setSelectedDispatch(null);
    setIsDetailModalOpen(false);
  };

  // Função para filtrar histórico com base na busca
  const filteredDispatchHistory = dispatchHistory.filter((dispatch) =>
    dispatch.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div
      style={{
        backgroundColor: '#242424',
        color: '#fff',
        padding: '30px',
        borderRadius: '12px',
        width: '100%',
        maxWidth: '1000px',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      {/* Cabeçalho com botão "Adicionar" */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* Título removido conforme solicitado */}
        <div></div>
        <button
          onClick={openCreateModal}
          style={{
            backgroundColor: '#242424',
            color: '#e5e7eb',
            padding: '10px 20px',
            border: '1px solid #555', // Adicionado border
            borderRadius: '6px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '16px',
            transition: 'background-color 0.3s, border 0.3s',
          }}
          aria-label="Adicionar Disparo"
        >
          <Plus size={20} />
          Adicionar
        </button>
      </div>

      {/* Título "Histórico de Disparos" */}
      <div>
        <h3 style={{ textAlign: 'center' }}>Histórico de Disparos</h3>
      </div>

      {/* Campo de Busca */}
      <div style={{ textAlign: 'center' }}>
        <input
          type="text"
          placeholder="Buscar disparo..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            backgroundColor: '#333',
            color: '#fff',
            padding: '10px 15px',
            borderRadius: '6px',
            border: '1px solid #555',
            width: '80%',
            maxWidth: '400px',
            fontSize: '16px',
          }}
          aria-label="Buscar Disparo"
        />
      </div>

      {/* Histórico de Disparos */}
      <div>
        {historyLoading ? (
          <div style={{ textAlign: 'center' }}>Carregando histórico...</div>
        ) : historyError ? (
          <div style={{ textAlign: 'center', color: '#ff6b6b' }}>{historyError}</div>
        ) : dispatchHistory.length === 0 ? (
          <div style={{ textAlign: 'center' }}>Nenhum disparo realizado ainda.</div>
        ) : (
          <div>
            {filteredDispatchHistory.length === 0 ? (
              <div style={{ textAlign: 'center' }}>Nenhum disparo encontrado.</div>
            ) : (
              <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                <thead>
                  <tr>
                    <th style={tableHeaderStyle}>Nome</th>
                    <th style={tableHeaderStyle}>Data/Hora</th>
                    <th style={tableHeaderStyle}>Qtda de Contatos</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredDispatchHistory.map((dispatch) => (
                    <tr
                      key={dispatch.id}
                      style={tableRowStyle}
                      onClick={() => openDetailModal(dispatch)}
                      onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#3a3a3a')}
                      onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
                      aria-label={`Selecionar disparo ${dispatch.name}`}
                    >
                      <td style={tableCellStyle}>{dispatch.name}</td>
                      <td style={tableCellStyle}>{new Date(dispatch.scheduledTime).toLocaleString()}</td>
                      <td style={tableCellStyle}>{dispatch.quantity}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>

      {/* Modal para Criação de Disparo */}
      {isModalOpen && (
        <div style={modalOverlayStyle}>
          <div style={modalContentStyle}>
            {/* Botão "X" para fechar a modal */}
            <button
              onClick={closeCreateModal}
              style={closeButtonStyle}
              aria-label="Fechar criação de disparo"
            >
              <X size={20} />
            </button>

            {/* Seção de Seleção de Contatos */}
            <div style={{ marginBottom: '20px' }}>
              <h3>Selecionar Contatos</h3>
              <div
                style={{
                  maxHeight: '200px',
                  overflowY: 'auto',
                  backgroundColor: '#333',
                  padding: '10px',
                  borderRadius: '8px',
                }}
              >
                {/* Adicionar um contato fictício */}
                <div style={{ marginBottom: '10px' }}>
                  <input
                    type="checkbox"
                    id={`contact-${fictitiousContact.id}`}
                    checked={selectedContacts.find((c) => c.id === fictitiousContact.id) ? true : false}
                    onChange={() => handleContactSelection(fictitiousContact)}
                  />
                  <label htmlFor={`contact-${fictitiousContact.id}`} style={{ marginLeft: '8px' }}>
                    {fictitiousContact.name} ({fictitiousContact.phone})
                  </label>
                </div>
              </div>
            </div>

            {/* Seção de Mensagem */}
            <div style={{ marginBottom: '20px' }}>
              <h3>Mensagem</h3>
              <textarea
                value={messageContent}
                onChange={(e) => setMessageContent(e.target.value)}
                placeholder="Digite sua mensagem aqui..."
                rows={5}
                style={{
                  width: '100%',
                  padding: '12px',
                  borderRadius: '6px',
                  border: '1px solid #555',
                  backgroundColor: '#333',
                  color: '#fff',
                  resize: 'vertical',
                  fontSize: '16px',
                }}
              />
            </div>

            {/* Seção de Agendamento */}
            <div style={{ marginBottom: '20px' }}>
              <h3>Agendar Data e Hora</h3>
              <DatePicker
                selected={scheduledDate}
                onChange={(date) => setScheduledDate(date)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="Pp"
                placeholderText="Selecione a data/hora"
                wrapperClassName="datePickerWrapper" // Utilizar uma classe específica
                popperPlacement="bottom-start"
              />
            </div>

            {/* Mensagens de Feedback */}
            {createMessage && (
              <div
                style={{
                  marginBottom: '20px',
                  padding: '10px',
                  borderRadius: '8px',
                  backgroundColor: createMessage.type === 'success' ? '#4caf50' : '#ff6b6b',
                  textAlign: 'center',
                  fontSize: '16px',
                }}
              >
                {createMessage.text}
              </div>
            )}

            {/* Botão "Enviar" */}
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
              <button
                onClick={handleSendMessage}
                disabled={createLoading}
                style={{
                  backgroundColor: '#242424',
                  color: '#e5e7eb',
                  padding: '10px 20px',
                  border: '1px solid #555', // Adicionado border
                  borderRadius: '6px',
                  cursor: 'pointer',
                  fontSize: '16px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  transition: 'background-color 0.3s, border 0.3s',
                }}
                aria-label="Enviar mensagem"
              >
                <Send size={20} />
                {createLoading ? 'Enviando...' : 'Enviar'}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal para Visualização de Detalhes do Disparo */}
      {isDetailModalOpen && selectedDispatch && (
        <div style={modalOverlayStyle}>
          <div style={detailModalContentStyle}>
            {/* Botão "X" para fechar a modal de detalhes */}
            <button
              onClick={closeDetailModal}
              style={closeButtonStyle}
              aria-label="Fechar detalhes do disparo"
            >
              <X size={20} />
            </button>

            <h3 style={{ textAlign: 'center' }}>Detalhes do Disparo</h3>
            <div style={detailRowStyle}>
              <strong>Nome:</strong>
              <span>{selectedDispatch.name}</span>
            </div>
            <div style={detailRowStyle}>
              <strong>Data/Hora:</strong>
              <span>{new Date(selectedDispatch.scheduledTime).toLocaleString()}</span>
            </div>
            <div style={detailRowStyle}>
              <strong>Quantidade de Contatos:</strong>
              <span>{selectedDispatch.quantity}</span>
            </div>
            <div style={detailRowStyle}>
              <strong>Mensagem:</strong>
              <span>{selectedDispatch.message}</span>
            </div>
            <div style={detailRowStyle}>
              <strong>Contatos:</strong>
              <ul style={{ paddingLeft: '20px' }}>
                {selectedDispatch.contacts.map((contact) => (
                  <li key={contact.id}>
                    {contact.name} ({contact.phone})
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// Estilos reutilizáveis
const tableHeaderStyle = {
  borderBottom: '2px solid #444654',
  padding: '10px',
  textAlign: 'left',
};

const tableRowStyle = {
  borderBottom: '1px solid #444654',
  cursor: 'pointer', // Linha clicável
  transition: 'background-color 0.2s',
};

const tableCellStyle = {
  padding: '10px',
};

const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const modalContentStyle = {
  backgroundColor: '#242424',
  padding: '30px',
  borderRadius: '12px',
  width: '90%',
  maxWidth: '500px',
  boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  position: 'relative', // Para posicionar o botão "X"
};

const detailModalContentStyle = {
  backgroundColor: '#242424',
  padding: '30px',
  borderRadius: '12px',
  width: '90%',
  maxWidth: '500px',
  boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  position: 'relative', // Para posicionar o botão "X"
};

const closeButtonStyle = {
  position: 'absolute',
  top: '15px',
  right: '15px',
  backgroundColor: 'transparent',
  border: 'none',
  color: '#fff',
  cursor: 'pointer',
};

const detailRowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px 0',
  borderBottom: '1px solid #444654',
};

export default MessageDispatcher;
