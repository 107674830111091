import React, { useState, useEffect, useContext } from 'react';
import { getAuth } from 'firebase/auth';
import { CompanyContext } from '../contexts/CompanyContext';
import { Lightbulb, Plus, Trash2, Edit2, X, ArrowUp, ArrowDown, Save } from 'lucide-react';
import { Tooltip, IconButton, TextField, MenuItem, Box, Radio, RadioGroup, FormControlLabel } from '@mui/material';

const WhatsAppTemplates = ({ onBack }) => {
  const { selectedCompany } = useContext(CompanyContext);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [filters, setFilters] = useState({
    category: '',
    language: '',
    status: '',
    period: ''
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    // Em modelos com subdivisões de conteúdo, estes campos serão usados
    headerType: '',
    body: '',
    footer: '',
    // Campos de catálogo e flow:
    catalogFormat: '',
    flowSelection: '',
    buttonLabel: '',
    //
    category: '',
    language: 'pt_BR',
    status: 'active_high',
    deliveredMessages: 0,
    readRate: 0,
    blockReason: '',
    lastEdit: new Date().toISOString(),
    templateType: ''
  });
  const [showCreateModal, setShowCreateModal] = useState(false);
  // Estado para etapa de configuração: "config" para escolher categoria/tipo ou "form" para preencher os dados
  const [configStep, setConfigStep] = useState("config");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedType, setSelectedType] = useState("");

  // Estilo base para botões (inspirado no UserConfig)
  const buttonStyle = {
    backgroundColor: '#242424',
    color: '#e5e7eb',
    padding: '10px 20px',
    border: '1px solid #555',
    borderRadius: '6px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '16px',
    transition: 'background-color 0.3s, border 0.3s'
  };

  const tableHeaderStyle = {
    borderBottom: '2px solid #444654',
    padding: '10px',
    textAlign: 'center',
    fontSize: '16px'
  };

  const tableRowStyle = {
    borderBottom: '1px solid #444654',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    textAlign: 'center'
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      setLoading(true);
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const companyId = selectedCompany?.companyId;
      if (!companyId) {
        setMessage({ type: 'error', text: 'Empresa não selecionada.' });
        return;
      }
      const response = await fetch(
        `https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/api/get-templates?companyId=${companyId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data = await response.json();
      if (response.ok && data.templates) {
        setTemplates(data.templates);
      } else {
        setMessage({ type: 'error', text: 'Erro ao carregar templates.' });
      }
    } catch (error) {
      console.error('Erro ao buscar templates:', error);
      setMessage({ type: 'error', text: 'Erro ao carregar templates.' });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const companyId = selectedCompany?.companyId;
      const url = editingTemplate
        ? `https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/api/templates/${editingTemplate.id}`
        : 'https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/api/templates';
      const method = editingTemplate ? 'PUT' : 'POST';
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ ...formData, companyId })
      });
      const data = await response.json();
      if (response.ok) {
        setMessage({ type: 'success', text: `Template ${editingTemplate ? 'atualizado' : 'criado'} com sucesso!` });
        setShowAddForm(false);
        setEditingTemplate(null);
        setFormData({
          name: '',
          headerType: '',
          body: '',
          footer: '',
          catalogFormat: '',
          flowSelection: '',
          buttonLabel: '',
          category: '',
          language: 'pt_BR',
          status: 'active_high',
          deliveredMessages: 0,
          readRate: 0,
          blockReason: '',
          lastEdit: new Date().toISOString(),
          templateType: ''
        });
        setConfigStep("config");
        setSelectedCategory("");
        setSelectedType("");
        fetchTemplates();
      } else {
        setMessage({ type: 'error', text: data.error || `Erro ao ${editingTemplate ? 'atualizar' : 'criar'} template.` });
      }
    } catch (error) {
      console.error('Erro ao salvar template:', error);
      setMessage({ type: 'error', text: `Erro ao ${editingTemplate ? 'atualizar' : 'criar'} template.` });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (templateId) => {
    if (!window.confirm('Tem certeza que deseja excluir este template?')) return;
    try {
      setLoading(true);
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(
        `https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/api/templates/${templateId}`,
        { method: 'DELETE', headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.ok) {
        setMessage({ type: 'success', text: 'Template excluído com sucesso!' });
        fetchTemplates();
      } else {
        const data = await response.json();
        setMessage({ type: 'error', text: data.error || 'Erro ao excluir template.' });
      }
    } catch (error) {
      console.error('Erro ao excluir template:', error);
      setMessage({ type: 'error', text: 'Erro ao excluir template.' });
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (template) => {
    setEditingTemplate(template);
    setFormData({
      name: template.name,
      headerType: template.headerType || '',
      body: template.body || '',
      footer: template.footer || '',
      catalogFormat: template.catalogFormat || '',
      flowSelection: template.flowSelection || '',
      buttonLabel: template.buttonLabel || '',
      category: template.category,
      language: template.language,
      status: template.status,
      deliveredMessages: template.deliveredMessages,
      readRate: template.readRate,
      blockReason: template.blockReason,
      lastEdit: template.lastEdit,
      templateType: template.templateType || ''
    });
    setShowAddForm(true);
    setConfigStep("form");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const renderSortableHeader = (key, label) => (
    <div onClick={() => requestSort(key)} style={{ display: 'flex', alignItems: 'center', gap: '4px', cursor: 'pointer', userSelect: 'none' }}>
      {label}
      {sortConfig.key === key ? (sortConfig.direction === 'asc' ? <ArrowUp size={16} /> : <ArrowDown size={16} />) : null}
    </div>
  );

  const renderTableCell = (content, maxWidth = '200px') => (
    <Tooltip title={content} placement="top">
      <div style={{ maxWidth, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'center' }}>
        {content}
      </div>
    </Tooltip>
  );

  const sortedTemplates = React.useMemo(() => {
    if (!sortConfig.key) return templates;
    return [...templates].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
  }, [templates, sortConfig]);

  const filteredTemplates = sortedTemplates.filter(template => {
    const matchesSearch = template.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      template.content.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = !filters.category || template.category === filters.category;
    const matchesLanguage = !filters.language || template.language === filters.language;
    const matchesStatus = !filters.status || template.status === filters.status;
    return matchesSearch && matchesCategory && matchesLanguage && matchesStatus;
  });

  return (
    <div style={{
      backgroundColor: '#242424',
      color: '#fff',
      padding: '30px',
      borderRadius: '12px',
      width: '100%',
      maxWidth: '1200px',
      boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
      display: 'flex',
      flexDirection: 'column',
      gap: '40px',
      margin: '0 auto',
      position: 'relative'
    }}>
      {/* Botão + Adicionar posicionado no topo */}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button onClick={() => {
          setShowCreateModal(true);
          setConfigStep("config");
          setSelectedCategory("");
          setSelectedType("");
        }} style={buttonStyle}>
          <Plus size={20} />
          Adicionar
        </button>
      </div>

      {/* Título centralizado */}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <h3 style={{ margin: 0, fontSize: '16px', fontWeight: '500', textAlign: 'center' }}>
          Listagem de modelo de mensagem
        </h3>
      </div>

      {/* Campo de busca centralizado */}
      <div style={{ textAlign: 'center', width: '100%' }}>
        <input type="text" placeholder="Buscar modelo de mensagem..." value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          style={{
            backgroundColor: '#333',
            color: '#fff',
            padding: '10px 15px',
            borderRadius: '6px',
            border: '1px solid #555',
            width: '80%',
            maxWidth: '400px',
            fontSize: '16px'
          }}
        />
      </div>

      {/* Filtros centralizados */}
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px', flexWrap: 'wrap', mb: 3 }}>
        <TextField select label="Categoria" value={filters.category} onChange={e => setFilters(prev => ({ ...prev, category: e.target.value }))}
          sx={{
            minWidth: '200px',
            '& .MuiOutlinedInput-root': {
              color: '#fff',
              backgroundColor: '#242424',
              '& fieldset': { borderColor: '#555' },
              '&:hover fieldset': { borderColor: '#666' },
              '&.Mui-focused fieldset': { borderColor: '#666' }
            },
            '& .MuiInputLabel-root': { color: '#aaa' },
            '& .MuiSelect-icon': { color: '#aaa' }
          }}>
          <MenuItem value="">Todas</MenuItem>
          <MenuItem value="marketing">Marketing</MenuItem>
          <MenuItem value="utilidade">Utilidade</MenuItem>
          <MenuItem value="autenticacao">Autenticação</MenuItem>
        </TextField>
        <TextField select label="Idioma" value={filters.language} onChange={e => setFilters(prev => ({ ...prev, language: e.target.value }))}
          sx={{
            minWidth: '200px',
            '& .MuiOutlinedInput-root': {
              color: '#fff',
              backgroundColor: '#242424',
              '& fieldset': { borderColor: '#555' },
              '&:hover fieldset': { borderColor: '#666' },
              '&.Mui-focused fieldset': { borderColor: '#666' }
            },
            '& .MuiInputLabel-root': { color: '#aaa' },
            '& .MuiSelect-icon': { color: '#aaa' }
          }}>
          <MenuItem value="">Todos</MenuItem>
          <MenuItem value="pt_BR">Português (Brasil)</MenuItem>
          <MenuItem value="en_US">English</MenuItem>
          <MenuItem value="es_ES">Español</MenuItem>
        </TextField>
        <TextField select label="Status" value={filters.status} onChange={e => setFilters(prev => ({ ...prev, status: e.target.value }))}
          sx={{
            minWidth: '200px',
            '& .MuiOutlinedInput-root': {
              color: '#fff',
              backgroundColor: '#242424',
              '& fieldset': { borderColor: '#555' },
              '&:hover fieldset': { borderColor: '#666' },
              '&.Mui-focused fieldset': { borderColor: '#666' }
            },
            '& .MuiInputLabel-root': { color: '#aaa' },
            '& .MuiSelect-icon': { color: '#aaa' }
          }}>
          <MenuItem value="">Todos</MenuItem>
          <MenuItem value="active_high">Ativo — Alta qualidade</MenuItem>
          <MenuItem value="active_low">Ativo — baixa qualidade</MenuItem>
          <MenuItem value="active_pending">Ativo — Qualidade pendente</MenuItem>
          <MenuItem value="active_medium">Ativo – Qualidade média</MenuItem>
          <MenuItem value="disabled">Desabilitado</MenuItem>
          <MenuItem value="appeal_analysis">Apelação feita – Em análise</MenuItem>
          <MenuItem value="paused">Pausado</MenuItem>
        </TextField>
        <TextField select label="Período" value={filters.period} onChange={e => setFilters(prev => ({ ...prev, period: e.target.value }))}
          sx={{
            minWidth: '200px',
            '& .MuiOutlinedInput-root': {
              color: '#fff',
              backgroundColor: '#242424',
              '& fieldset': { borderColor: '#555' },
              '&:hover fieldset': { borderColor: '#666' },
              '&.Mui-focused fieldset': { borderColor: '#666' }
            },
            '& .MuiInputLabel-root': { color: '#aaa' },
            '& .MuiSelect-icon': { color: '#aaa' },
            '& .MuiSelect-select': { color: '#fff' }
          }}>
          <MenuItem value="">Todos</MenuItem>
          <MenuItem value="7">Últimos 7 dias</MenuItem>
          <MenuItem value="15">Últimos 15 dias</MenuItem>
          <MenuItem value="30">Últimos 30 dias</MenuItem>
          <MenuItem value="60">Últimos 60 dias</MenuItem>
          <MenuItem value="90">Últimos 90 dias</MenuItem>
        </TextField>
      </Box>

      <div style={{
        overflowX: 'auto',
        backgroundColor: '#242424',
        borderRadius: '8px',
        padding: '20px'
      }}>
        <table style={{
          width: '100%',
          borderCollapse: 'collapse',
          fontSize: '14px'
        }}>
          <thead>
            <tr style={tableHeaderStyle}>
              <th style={{ width: '10%' }}>Nome do Modelo</th>
              <th style={{ width: '10%' }}>Categoria</th>
              <th style={{ width: '10%' }}>Idioma</th>
              <th style={{ width: '10%' }}>Status</th>
              <th style={{ width: '10%' }}>Mensagens Entregues</th>
              <th style={{ width: '10%' }}>Taxa de Leitura</th>
              <th style={{ width: '10%' }}>Motivo de Bloqueio</th>
              <th style={{ width: '10%' }}>Última Edição</th>
            </tr>
          </thead>
          <tbody>
            {filteredTemplates.map(template => (
              <tr key={template.id} style={tableRowStyle}
                onMouseEnter={e => e.currentTarget.style.backgroundColor = '#3a3a3a'}
                onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}>
                <td style={{ padding: '12px' }}>{template.name}</td>
                <td style={{ padding: '12px' }}>{template.category}</td>
                <td style={{ padding: '12px' }}>{template.language}</td>
                <td style={{ padding: '12px' }}>{template.status || 'Ativo'}</td>
                <td style={{ padding: '12px' }}>{template.deliveredMessages?.toString() || '0'}</td>
                <td style={{ padding: '12px' }}>{`${template.readRate?.toString() || '0'}%`}</td>
                <td style={{ padding: '12px' }}>{template.blockReason || 'Nenhum'}</td>
                <td style={{ padding: '12px' }}>{new Date(template.lastEdit).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {message && (
        <div style={{
          padding: '10px',
          backgroundColor: '#333',
          borderRadius: '6px',
          textAlign: 'center',
          border: '1px solid #555'
        }}>
          {message.text}
        </div>
      )}

      {showCreateModal && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000
        }}>
          <div style={{
            backgroundColor: '#242424',
            padding: '30px',
            borderRadius: '12px',
            width: '90%',
            maxWidth: '800px',
            maxHeight: '90vh',
            overflowY: 'auto',
            position: 'relative'
          }}>
            {configStep === "config" && (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h3 style={{ margin: 0, fontSize: '18px' }}>Configurar seu modelo</h3>
                  <button onClick={() => setShowCreateModal(false)} style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    color: '#fff',
                    cursor: 'pointer',
                    padding: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <X size={24} />
                  </button>
                </div>
                <p style={{ fontSize: '14px' }}>
                  Escolha a categoria que melhor descreve seu modelo de mensagem. Em seguida, selecione o tipo de mensagem que deseja enviar.
                </p>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <button style={{
                    ...buttonStyle,
                    backgroundColor: selectedCategory === "marketing" ? "#666" : buttonStyle.backgroundColor
                  }} onClick={() => setSelectedCategory("marketing")}>
                    Marketing
                  </button>
                  <button style={{
                    ...buttonStyle,
                    backgroundColor: selectedCategory === "utilidade" ? "#666" : buttonStyle.backgroundColor
                  }} onClick={() => setSelectedCategory("utilidade")}>
                    Utilidade
                  </button>
                  <button style={{
                    ...buttonStyle,
                    backgroundColor: selectedCategory === "autenticacao" ? "#666" : buttonStyle.backgroundColor
                  }} onClick={() => setSelectedCategory("autenticacao")}>
                    Autenticação
                  </button>
                </div>
                {selectedCategory && (
                  <>
                    <RadioGroup value={selectedType} onChange={e => setSelectedType(e.target.value)}>
                      {selectedCategory === "marketing" && (
                        <>
                          <FormControlLabel value="personalizada" control={<Radio style={{ color: '#e5e7eb' }} />} label="Personalizada: Envie promoções ou anúncios para aumentar o reconhecimento e o engajamento." />
                          <FormControlLabel value="catalogo" control={<Radio style={{ color: '#e5e7eb' }} />} label="Catálogo: Envie mensagens sobre o catálogo inteiro ou vários produtos dele." />
                          <FormControlLabel value="flows" control={<Radio style={{ color: '#e5e7eb' }} />} label="Flows: Envie um formulário para coletar interesses dos clientes e solicitações de horas marcadas ou fazer pesquisas." />
                        </>
                      )}
                      {selectedCategory === "utilidade" && (
                        <>
                          <FormControlLabel value="personalizada" control={<Radio style={{ color: '#e5e7eb' }} />} label="Personalizada: Envie mensagens sobre uma conta ou pedido existente." />
                          <FormControlLabel value="flows" control={<Radio style={{ color: '#e5e7eb' }} />} label="Flows: Envie um formulário para coletar feedback, enviar lembretes ou gerenciar pedidos." />
                        </>
                      )}
                      {selectedCategory === "autenticacao" && (
                        <FormControlLabel value="codigo_unico" control={<Radio style={{ color: '#e5e7eb' }} />} label="Código de acesso de uso único: Envie códigos para verificar uma transação ou login." />
                      )}
                    </RadioGroup>
                    {selectedType && (
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button style={buttonStyle} onClick={() => {
                          setFormData(prev => ({ ...prev, category: selectedCategory, templateType: selectedType }));
                          setConfigStep("form");
                        }}>
                          Continuar
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
            {configStep === "form" && formData.category === "marketing" && formData.templateType === "personalizada" && (
              <div style={{ display: 'flex', gap: '20px' }}>
                {/* Formulário para Marketing: Personalizada (já implementado anteriormente) */}
                <div style={{ flex: 2 }}>
                  <h4 style={{ margin: '10px 0' }}>Nome e idioma do modelo</h4>
                  <TextField
                    fullWidth
                    label="Dê um nome ao seu modelo"
                    placeholder="Insira um nome para o modelo"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    inputProps={{ maxLength: 512 }}
                    helperText={`${formData.name.length}/512`}
                    sx={{ marginBottom: '20px', backgroundColor: '#242424' }}
                  />
                  <TextField
                    select
                    fullWidth
                    label="Selecione o idioma"
                    name="language"
                    value={formData.language}
                    onChange={handleChange}
                    sx={{ marginBottom: '20px', backgroundColor: '#242424' }}
                  >
                    <MenuItem value="en_US">English</MenuItem>
                    <MenuItem value="pt_BR">Português</MenuItem>
                  </TextField>
                  <h4 style={{ margin: '10px 0' }}>Conteúdo</h4>
                  <div style={{ marginBottom: '20px' }}>
                    <p style={{ fontSize: '14px', margin: '5px 0' }}>Cabeçalho · Opcional</p>
                    <TextField
                      fullWidth
                      label="Tipo de cabeçalho"
                      placeholder="Nenhum"
                      name="headerType"
                      value={formData.headerType}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={{ marginBottom: '20px' }}>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      label="Corpo"
                      placeholder="Insira o texto em English"
                      name="body"
                      value={formData.body || 'Hello'}
                      onChange={handleChange}
                      inputProps={{ maxLength: 1024 }}
                      helperText={`${formData.body ? formData.body.length : 5}/1024`}
                    />
                  </div>
                  <div style={{ marginBottom: '20px' }}>
                    <TextField
                      fullWidth
                      label="Rodapé"
                      placeholder="Inserir texto"
                      name="footer"
                      value={formData.footer}
                      onChange={handleChange}
                      inputProps={{ maxLength: 60 }}
                      helperText={`${formData.footer ? formData.footer.length : 0}/60`}
                    />
                  </div>
                  <div style={{ marginBottom: '20px' }}>
                    <p style={{ fontSize: '14px', margin: '5px 0' }}>
                      Botões · Opcional<br />
                      Somente um botão é aceito para esse tipo de modelo. O texto do botão não é editável.
                    </p>
                    <button style={buttonStyle}>Adicionar Botão</button>
                  </div>
                </div>
                <div style={{ flex: 1, backgroundColor: '#333', padding: '10px', borderRadius: '6px' }}>
                  <h4 style={{ color: '#fff', marginBottom: '10px' }}>Prévia do Modelo</h4>
                  <div style={{ color: '#fff', fontSize: '14px' }}>
                    <p><strong>Nome:</strong> {formData.name}</p>
                    <p><strong>Idioma:</strong> {formData.language}</p>
                    <p><strong>Cabeçalho:</strong> {formData.headerType || 'Nenhum'}</p>
                    <p><strong>Corpo:</strong> {formData.body || 'Hello'}</p>
                    <p><strong>Rodapé:</strong> {formData.footer}</p>
                  </div>
                </div>
              </div>
            )}
            {configStep === "form" && formData.category === "marketing" && formData.templateType === "catalogo" && (
              <div style={{ display: 'flex', gap: '20px' }}>
                {/* Formulário para Marketing: Catálogo */}
                <div style={{ flex: 2 }}>
                  <h4 style={{ margin: '10px 0' }}>Nome e idioma do modelo</h4>
                  <TextField
                    fullWidth
                    label="Dê um nome ao seu modelo"
                    placeholder="Insira um nome para o modelo"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    inputProps={{ maxLength: 512 }}
                    helperText={`${formData.name.length}/512`}
                    sx={{ marginBottom: '20px', backgroundColor: '#242424' }}
                  />
                  <TextField
                    select
                    fullWidth
                    label="Selecione o idioma"
                    name="language"
                    value={formData.language}
                    onChange={handleChange}
                    sx={{ marginBottom: '20px', backgroundColor: '#242424' }}
                  >
                    <MenuItem value="en_US">English</MenuItem>
                    <MenuItem value="pt_BR">Português</MenuItem>
                  </TextField>
                  <h4 style={{ margin: '10px 0' }}>Formato do catálogo</h4>
                  <RadioGroup
                    value={formData.catalogFormat}
                    onChange={e => setFormData(prev => ({ ...prev, catalogFormat: e.target.value }))}
                  >
                    <FormControlLabel
                      value="catalogo_inteiro"
                      control={<Radio style={{ color: '#e5e7eb' }} />}
                      label="Mensagem do catálogo: Inclua o catálogo inteiro para oferecer uma visualização abrangente de todos os seus produtos aos usuários."
                    />
                    <FormControlLabel
                      value="multiproduto"
                      control={<Radio style={{ color: '#e5e7eb' }} />}
                      label="Mensagem multiproduto: Inclua até 30 produtos do catálogo. Útil para mostrar uma nova coleção ou uma categoria de produtos específica. Você precisará especificar os produtos usando a API."
                    />
                  </RadioGroup>
                  <p style={{ fontSize: '12px', margin: '10px 0', color: '#aaa' }}>
                    Ao conectar um catálogo, você permitirá que seus clientes vejam, mandem mensagens e enviem carrinhos com seus produtos e serviços pelo WhatsApp. Não é possível adicionar mídia a este modelo. Como alternativa, você pode vincular seu catálogo para exibir seus produtos.
                  </p>
                  <h4 style={{ margin: '10px 0' }}>Conteúdo</h4>
                  <div style={{ marginBottom: '20px' }}>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      label="Corpo"
                      placeholder="Insira o texto em English"
                      name="body"
                      value={formData.body || 'Hello'}
                      onChange={handleChange}
                      inputProps={{ maxLength: 1024 }}
                      helperText={`${formData.body ? formData.body.length : 5}/1024`}
                    />
                  </div>
                  <div style={{ marginBottom: '20px' }}>
                    <TextField
                      fullWidth
                      label="Rodapé"
                      placeholder="Inserir texto"
                      name="footer"
                      value={formData.footer}
                      onChange={handleChange}
                      inputProps={{ maxLength: 60 }}
                      helperText={`${formData.footer ? formData.footer.length : 0}/60`}
                    />
                  </div>
                  <div style={{ marginBottom: '20px' }}>
                    <p style={{ fontSize: '14px', margin: '5px 0' }}>
                      Botão · Opcional<br />
                      Somente um botão é aceito para esse tipo de modelo. O texto do botão não é editável.
                    </p>
                    <TextField
                      fullWidth
                      value="Botão padrão"
                      disabled
                      sx={{ backgroundColor: '#333' }}
                    />
                  </div>
                </div>
                <div style={{ flex: 1, backgroundColor: '#333', padding: '10px', borderRadius: '6px' }}>
                  <h4 style={{ color: '#fff', marginBottom: '10px' }}>Prévia do Modelo</h4>
                  <div style={{ color: '#fff', fontSize: '14px' }}>
                    <p><strong>Nome:</strong> {formData.name}</p>
                    <p><strong>Idioma:</strong> {formData.language}</p>
                    <p><strong>Formato:</strong> {formData.catalogFormat === "catalogo_inteiro" ? "Mensagem do catálogo" : formData.catalogFormat === "multiproduto" ? "Mensagem multiproduto" : ""}</p>
                    <p><strong>Corpo:</strong> {formData.body || 'Hello'}</p>
                    <p><strong>Rodapé:</strong> {formData.footer}</p>
                    <p><strong>Botão:</strong> Botão padrão</p>
                  </div>
                </div>
              </div>
            )}
            {configStep === "form" && formData.category === "marketing" && formData.templateType === "flows" && (
              <div style={{ display: 'flex', gap: '20px' }}>
                {/* Formulário para Marketing Flow */}
                <div style={{ flex: 2 }}>
                  <h4 style={{ margin: '10px 0' }}>Nome e idioma do modelo</h4>
                  <TextField
                    fullWidth
                    label="Dê um nome ao seu modelo"
                    placeholder="Insira um nome para o modelo"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    inputProps={{ maxLength: 512 }}
                    helperText={`${formData.name.length}/512`}
                    sx={{ marginBottom: '20px', backgroundColor: '#242424' }}
                  />
                  <TextField
                    select
                    fullWidth
                    label="Selecione o idioma"
                    name="language"
                    value={formData.language}
                    onChange={handleChange}
                    sx={{ marginBottom: '20px', backgroundColor: '#242424' }}
                  >
                    <MenuItem value="en_US">English</MenuItem>
                    <MenuItem value="pt_BR">Português</MenuItem>
                  </TextField>
                  <h4 style={{ margin: '10px 0' }}>Configuração do flow</h4>
                  <TextField
                    select
                    fullWidth
                    label="Escolha um flow"
                    name="flowSelection"
                    value={formData.flowSelection}
                    onChange={handleChange}
                    sx={{ marginBottom: '20px', backgroundColor: '#242424' }}
                  >
                    <MenuItem value="flow1">Flow 1</MenuItem>
                    <MenuItem value="flow2">Flow 2</MenuItem>
                    <MenuItem value="novo">Criar novo flow</MenuItem>
                  </TextField>
                  <h4 style={{ margin: '10px 0' }}>Conteúdo</h4>
                  <div style={{ marginBottom: '20px' }}>
                    <TextField
                      fullWidth
                      label="Cabeçalho · Opcional"
                      placeholder="Nenhum"
                      name="headerType"
                      value={formData.headerType}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={{ marginBottom: '20px' }}>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      label="Corpo"
                      placeholder="Insira o texto em English"
                      name="body"
                      value={formData.body || 'Hello'}
                      onChange={handleChange}
                      inputProps={{ maxLength: 1024 }}
                      helperText={`${formData.body ? formData.body.length : 5}/1024`}
                    />
                  </div>
                  <div style={{ marginBottom: '20px' }}>
                    <TextField
                      fullWidth
                      label="Rodapé"
                      placeholder="Inserir texto"
                      name="footer"
                      value={formData.footer}
                      onChange={handleChange}
                      inputProps={{ maxLength: 60 }}
                      helperText={`${formData.footer ? formData.footer.length : 0}/60`}
                    />
                  </div>
                  <div style={{ marginBottom: '20px' }}>
                    <TextField
                      fullWidth
                      label="Texto do botão"
                      placeholder="Edite o rótulo do botão"
                      name="buttonLabel"
                      value={formData.buttonLabel}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div style={{ flex: 1, backgroundColor: '#333', padding: '10px', borderRadius: '6px' }}>
                  <h4 style={{ color: '#fff', marginBottom: '10px' }}>Prévia do Modelo</h4>
                  <div style={{ color: '#fff', fontSize: '14px' }}>
                    <p><strong>Nome:</strong> {formData.name}</p>
                    <p><strong>Idioma:</strong> {formData.language}</p>
                    <p><strong>Flow:</strong> {formData.flowSelection}</p>
                    <p><strong>Cabeçalho:</strong> {formData.headerType || 'Nenhum'}</p>
                    <p><strong>Corpo:</strong> {formData.body || 'Hello'}</p>
                    <p><strong>Rodapé:</strong> {formData.footer}</p>
                    <p><strong>Botão:</strong> {formData.buttonLabel}</p>
                  </div>
                </div>
              </div>
            )}
            {configStep === "form" && formData.category !== "marketing" && (
              <div>
                <p>Layout para esta opção não foi implementado.</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WhatsAppTemplates;
