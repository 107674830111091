import React, { useState, useContext, useEffect } from 'react';
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup,
  linkWithPopup
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Icon from '../assets/Icon.png';
import { X } from 'lucide-react';
import { CompanyContext } from '../contexts/CompanyContext';

const GoogleLogo = ({ size = 24 }) => (
  <svg viewBox="0 0 24 24" width={size} height={size} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
      fill="#4285F4"
    />
    <path
      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
      fill="#34A853"
    />
    <path
      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
      fill="#FBBC05"
    />
    <path
      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
      fill="#EA4335"
    />
  </svg>
);

const CompanySelectionModal = ({ companies, onSelect, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4">
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
          aria-label="Fechar"
        >
          <X size={24} />
        </button>
        <h2 className="text-xl font-bold mb-6 text-center">Selecione uma Empresa</h2>
        <ul className="space-y-4">
          {companies.map((company) => (
            <li
              key={company.companyId}
              onClick={() => onSelect(company)}
              className="cursor-pointer p-4 border rounded hover:bg-gray-100"
            >
              <span className="text-lg">
                {company.companyName || 'Empresa sem nome'}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [companies, setCompanies] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const auth = getAuth();
  const navigate = useNavigate();
  const { updateSelectedCompany } = useContext(CompanyContext);

  // Função para voltar para a página de planos
  const handleBackToPlans = () => {
    window.location.assign('https://www.iatconsulting.com.br/pre%C3%A7os');
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken(true).then(idToken => {
          console.log('Token renovado automaticamente:', idToken ? 'Token presente' : 'Token ausente');
        });
      }
    });

    return () => unsubscribe();
  }, [auth]);

  // Modificação no fetch: se a API retornar 403, consideramos que este usuário não precisa de empresa associada.
  const fetchUserCompanies = async (idToken) => {
    try {
      const response = await fetch(
        'https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/api/user-companies',
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
          },
        }
      );
      console.log('Resposta da API:', response.status, response.statusText);
      if (response.ok) {
        const data = await response.json();
        console.log('Empresas retornadas:', data.companies);
        return data.companies || [];
      } else if (response.status === 403) {
        // Se 403, consideramos que o usuário não tem empresa (caso de cadastro via UserConfig)
        console.warn('403 ao buscar empresas, consideramos cadastro completo sem empresa.');
        return null;
      } else {
        console.error('Erro ao buscar empresas:', response.statusText);
        return [];
      }
    } catch (error) {
      console.error('Erro na requisição das empresas:', error);
      return [];
    }
  };

  const handlePostLogin = async (user) => {
    try {
      console.log('Iniciando handlePostLogin para usuário:', user.uid);
      // Força o refresh do token
      const [tokenResult, idToken] = await Promise.all([
        user.getIdTokenResult(true),
        user.getIdToken(true)
      ]);
      console.log('Token obtido:', idToken ? 'Token presente' : 'Token ausente');
      console.log('Claims do usuário:', tokenResult.claims);
      
      const claims = tokenResult.claims;
      const userCompanies = await fetchUserCompanies(idToken);

      // Se a API retornar null (403), assumimos que o cadastro está completo e o usuário não tem empresa associada
      if (userCompanies === null) {
        console.log('Usuário sem empresa associado via UserConfig. Redirecionando para dashboard.');
        navigate('/dashboard');
        return;
      }
      
      // Se o cadastro estiver incompleto (flag false) e não houver empresas, redireciona para o onboarding
      if (!claims.registrationComplete && userCompanies.length === 0) {
        console.log('Cadastro incompleto, redirecionando para onboarding');
        navigate('/complete-registration');
        return;
      }
      
      if (userCompanies.length === 0) {
        setError('Nenhuma empresa associada ao usuário.');
        return;
      }

      if (userCompanies.length === 1) {
        updateSelectedCompany(userCompanies[0]);
        navigate(`/chat?companyId=${userCompanies[0].companyId}`, { replace: true });
      } else {
        const sortedCompanies = userCompanies.sort((a, b) =>
          (a.companyName || '').localeCompare(b.companyName || '')
        );
        setCompanies(sortedCompanies);
        setShowModal(true);
      }
    } catch (error) {
      console.error('Erro detalhado no handlePostLogin:', error);
      setError('Ocorreu um erro ao processar o login. Por favor, tente novamente.');
    }
  };

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      // Força a renovação do token para capturar as custom claims atualizadas
      const idToken = await user.getIdToken(true);
      console.log('Token renovado após login:', idToken);
      await handlePostLogin(user);
    } catch (error) {
      console.error('Erro detalhado no login:', error);
      if (error.code === 'auth/wrong-password') {
        setError('Senha incorreta. Tente novamente.');
      } else if (error.code === 'auth/user-not-found') {
        setError('Usuário não encontrado. Por favor, cadastre-se.');
      } else if (error.code === 'auth/too-many-requests') {
        setError('Muitas tentativas de login. Tente novamente mais tarde.');
      } else if (error.message && error.message.includes('CPF ou CNPJ inválido')) {
        setError('CPF ou CNPJ inválido. Por favor, verifique e tente novamente.');
      } else if (error.message && error.message.includes('Número de WhatsApp inválido')) {
        setError('Número de WhatsApp inválido. Por favor, verifique e tente novamente.');
      } else if (error.message && error.message.includes('Conta está desativada')) {
        setError('Sua conta está desativada. Por favor, contate o suporte.');
      } else if (error.message && error.message.includes('Usuário está banido')) {
        setError('Sua conta foi banida. Por favor, contate o suporte.');
      } else {
        setError('Erro ao fazer login. Verifique suas credenciais.');
      }
    }
  };

  const handleGoogleLogin = async () => {
    setIsLoading(true);
    setError('');
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account',
      login_hint: email
    });
    
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      await handlePostLogin(user);
    } catch (error) {
      console.error('Erro ao fazer login com Google:', error);
      if (error.code === 'auth/account-exists-with-different-credential') {
        // Trata o erro de conta existente com credenciais diferentes
        const pendingCred = error.credential;
        const existingEmail = error.customData.email;
        // Se a senha estiver preenchida, tenta o login com email/senha e vincula as credenciais
        if (password) {
          try {
            const userCredential = await signInWithEmailAndPassword(auth, existingEmail, password);
            // Vincula a credencial do Google à conta existente
            await linkWithPopup(userCredential.user, provider);
            // Após vincular, recarrega o usuário para atualizar os claims
            await userCredential.user.reload();
            await handlePostLogin(userCredential.user);
          } catch (linkError) {
            console.error('Erro ao vincular credenciais:', linkError);
            setError('Erro ao vincular credenciais: ' + linkError.message);
          }
        } else {
          setError('Conta já existente. Por favor, faça login com email e senha para vincular sua conta do Google.');
        }
      } else if (error.code === 'auth/popup-closed-by-user') {
        setError('Login cancelado pelo usuário.');
      } else {
        setError('Erro ao fazer login com Google. Tente novamente.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError('Por favor, insira seu endereço de e-mail.');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Um e-mail para redefinição de senha foi enviado.');
      setError('');
    } catch (error) {
      console.error('Erro ao enviar e-mail de redefinição:', error);
      setError(
        error.code === 'auth/user-not-found'
          ? 'Esse e-mail não está cadastrado. Por favor, verifique ou faça o cadastro.'
          : 'Erro ao enviar e-mail de redefinição de senha. Verifique se o e-mail está correto.'
      );
    }
  };

  const handleCompanySelect = (company) => {
    console.log('Empresa selecionada pelo usuário:', company);
    updateSelectedCompany(company);
    setShowModal(false);
    navigate(`/chat?companyId=${company.companyId}`);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#242424] p-4">
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6 relative">
        {isLoading && (
          <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center rounded-lg z-50">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#f1c915]"></div>
          </div>
        )}
        <button
          onClick={handleBackToPlans}
          className="absolute top-4 left-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          aria-label="Voltar para página de planos"
        >
          <i className="fas fa-arrow-left text-xl"></i>
        </button>
        <div className="flex justify-center mb-6">
          <img src={Icon} alt="Logo" width={48} height={48} />
        </div>
        <form onSubmit={handleEmailLogin} className="space-y-4">
          {error && <div className="text-red-500">{error}</div>}
          {message && <div className="text-green-500">{message}</div>}
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
              Endereço de e-mail*
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="relative">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
              Senha*
            </label>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-2 top-9"
            >
              {showPassword ? (
                <i className="fas fa-eye-slash text-gray-500" style={{ fontSize: '20px' }}></i>
              ) : (
                <i className="fas fa-eye text-gray-500" style={{ fontSize: '20px' }}></i>
              )}
            </button>
          </div>
          <button type="submit" className="w-full bg-[#f1c915] hover:bg-[#242424] text-white py-2 rounded">
            Continuar
          </button>
        </form>
        <div className="mt-4 text-center">
          <button onClick={handleForgotPassword} className="text-sm text-[#242424] hover:underline">
            Esqueci minha senha
          </button>
        </div>
        <div className="mt-4 text-center">
          <span className="text-sm text-gray-600">Não tem uma conta? </span>
          <a href="https://www.iatconsulting.com.br/pre%C3%A7os" className="text-sm text-[#242424] hover:underline">
            Cadastrar
          </a>
        </div>
        <div className="mt-6 relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">ou</span>
          </div>
        </div>
        <div className="mt-6">
          <button
            type="button"
            onClick={handleGoogleLogin}
            className="w-full flex items-center justify-center space-x-2 border border-gray-300 py-2 rounded"
          >
            <GoogleLogo size={24} />
            <span>Login com o Google</span>
          </button>
        </div>
        <div className="mt-6 text-center text-xs text-gray-500">
          <a
            href="https://www.iatconsulting.com.br/termos-condicoes"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline"
          >
            Termos de uso
          </a>
          {' | '}
          <a
            href="https://www.iatconsulting.com.br/politica-privacidade"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline"
          >
            Política de privacidade
          </a>
        </div>
      </div>
      {showModal && (
        <CompanySelectionModal
          companies={companies}
          onSelect={handleCompanySelect}
          onClose={handleModalClose}
        />
      )}
    </div>
  );
};

export default LoginScreen;
