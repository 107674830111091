import React, { useState, useEffect, useRef, useContext } from 'react';
import { Send, Paperclip, MessageCircle, Lightbulb, Plus, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Icon from '../assets/Icon.png';
import whatsappLogo from '../assets/whatsapp.png'; // Importação da imagem do WhatsApp
import useFacebookSDK from '../hooks/useFacebookSDK';
import WhatsAppConfig from './WhatsAppConfig';
import BotConfig from './BotConfig';
import UserConfig from './UserConfig';
import MessageDispatcher from './MessageDispatcher';
import axios from 'axios';
import { auth } from '../firebase';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CompanyContext } from '../contexts/CompanyContext';

const ChatSettings = () => {
  // Utilize o CompanyContext para obter a empresa selecionada
  const { selectedCompany, updateSelectedCompany } = useContext(CompanyContext);

  const [messages, setMessages] = useState([]);
  const [defaultMessageShown, setDefaultMessageShown] = useState(true);
  const [currentView, setCurrentView] = useState('chat');
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [typedMessage, setTypedMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false); // Estado para o indicador de digitação
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        navigate('/');
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  useFacebookSDK('357319013845670', 'v20.0');

  const addMessage = (message, isUser) => {
    if (typeof message === 'object') {
      message = JSON.stringify(message); // Garantir que message seja uma string
    }
    console.log("Adicionando mensagem:", message);
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: message, isUser: isUser },
    ]);
    setDefaultMessageShown(false);
    console.log("Mensagem padrão oculta:", defaultMessageShown);
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const getDefaultMessage = () => {
    const currentHour = new Date().getHours();
    let greeting;
    if (currentHour < 12) {
      greeting = 'Bom dia';
    } else if (currentHour < 18) {
      greeting = 'Boa tarde';
    } else {
      greeting = 'Boa noite';
    }
  
    // Obtém o nome da empresa e do usuário a partir dos states
    const companyName = selectedCompany?.companyName || 'Sua Empresa';
    const userName = user?.displayName || 'usuário';
  
    return `${companyName}\n${greeting}, ${userName}! Sou Beny.Bot, assistente virtual da IAT.\nComo posso te ajudar?`;
  };  

  useEffect(() => {
    const message = getDefaultMessage();
    let currentIndex = 0;
    setTypedMessage('');
  
    if (message && message.length > 0) {
      const typingInterval = setInterval(() => {
        if (currentIndex < message.length) {
          const currentChar = message[currentIndex];
          console.log(`Adicionando caractere: ${currentChar} (índice: ${currentIndex})`);
          if (currentChar !== undefined) {
            setTypedMessage((prev) => prev + currentChar);
          }
          currentIndex++;
        } else {
          clearInterval(typingInterval);
        }
      }, 100);
  
      return () => clearInterval(typingInterval);
    }
  }, [selectedCompany, user]);  

  const callIATAssistant = async (userInput) => {
    try {
      console.log('Chamando assistente IAT com entrada do usuário:', userInput);
      setIsTyping(true); // Inicia o indicador de digitação
  
      const authInstance = getAuth();
      const currentUser = authInstance.currentUser;
      const idToken = currentUser && (await currentUser.getIdToken());
  
      if (!idToken) {
        console.error('Usuário não autenticado.');
        navigate('/');
        setIsTyping(false); // Para o indicador de digitação
        return;
      }
  
      // Recupera o ID da empresa selecionada a partir do contexto
      const companyId = selectedCompany?.companyId;
  
      const requestData = {
        userInput: userInput,
        chat_id: 'default_chat_id',
        companyId: companyId, // Adicionado para informar o backend
      };
      console.log('Dados da requisição:', requestData);
  
      const response = await axios.post(
        'https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/process-text',
        requestData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
  
      console.log('Resposta recebida do backend:', response.data);
  
      const aiResponse = response.data.message;
      let aiResponseText = '';
  
      if (typeof aiResponse === 'string') {
        aiResponseText = aiResponse;
      } else if (typeof aiResponse === 'object' && aiResponse !== null) {
        aiResponseText = aiResponse.message || 'Resposta inválida do assistente.';
      } else {
        aiResponseText = 'Resposta inválida do assistente.';
      }
  
      addMessage(aiResponseText, false);
    } catch (error) {
      console.error('Erro ao se comunicar com o backend:', error);
  
      if (error.response) {
        console.error('Dados da resposta de erro:', error.response.data);
        console.error('Status da resposta de erro:', error.response.status);
        console.error('Cabeçalhos da resposta de erro:', error.response.headers);
      }
  
      addMessage(
        'Desculpe, houve um erro ao processar sua solicitação.',
        false
      );
    } finally {
      setIsTyping(false); // Finaliza o indicador de digitação
    }
  };  

  const handleUserInput = (event) => {
    if (event.key === 'Enter' || event.type === 'click') {
      const userInput = document.getElementById('user-input').value.trim();
      console.log('Entrada do usuário:', userInput);

      if (userInput) {
        addMessage(userInput, true);
        callIATAssistant(userInput);
        document.getElementById('user-input').value = '';
      }
    }
  };

  const handleAttach = () => {
    console.log('Botão de anexo clicado');
    document.getElementById('file-input').click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log('Arquivo selecionado:', file.name);
      addMessage(`Você anexou: ${file.name}`, true);
    } else {
      console.log('Nenhum arquivo selecionado');
    }
  };

  const handleOptionClick = (optionText) => {
    console.log('Opção selecionada:', optionText);

    if (optionText === 'Config WhatsApp') {
      setCurrentView('whatsappConfig');
      console.log('Visualização atual alterada para: whatsappConfig');
    } else if (optionText === 'Config Bot') {
      setCurrentView('botConfig'); // Muda a visualização para a configuração do bot
    } else if (optionText === 'Config Usuário') {
      setCurrentView('userConfig'); // Muda a visualização para a configuração do usuário
    } else if (optionText === 'Disparo de Mensagem') {
      setCurrentView('messageDispatcher'); // Muda a visualização para o MessageDispatcher
    } else if (optionText === 'Gerenciar Assinatura') { // Nova opção: 'Gerenciar Assinatura'
      window.open('https://billing.stripe.com/p/login/8wM5o5gga0rM1dS288', '_blank');
      console.log('Redirecionando para Gerenciar Assinatura');
    } else {
      addMessage(`Você selecionou: ${optionText}`, true);
      setTimeout(() => {
        addMessage(`Ação iniciada para: ${optionText}`, false);
      }, 1000);
    }
  };

  const handleBack = () => {
    console.log('Botão voltar clicado');
    setCurrentView('chat');
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <div
      style={{
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#242424',
        color: '#e9edef',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* Definição da Animação de Piscar */}
      <style>
        {`
          @keyframes blink {
            0% { opacity: 1; }
            50% { opacity: 0.3; }
            100% { opacity: 1; }
          }
          .blinking-icon {
            animation: blink 1s infinite;
          }
        `}
      </style>

      {/* Cabeçalho com botão de voltar e logo centralizado */}
      <div
        style={{
          textAlign: 'center',
          padding: '20px',
          backgroundColor: '#242424',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        {currentView !== 'chat' && (
          <button
            onClick={handleBack}
            className="flex items-center justify-center text-gray-400"
            style={{ background: 'none', border: 'none', cursor: 'pointer' }}
            aria-label="Voltar"
          >
            <span style={{ marginRight: '8px', fontSize: '16px' }}>←</span>
            <span>Voltar</span>
          </button>
        )}
        {currentView === 'chat' && (
          <button
            onClick={() => navigate('/chat')}
            className="flex items-center justify-center text-gray-400"
            style={{ background: 'none', border: 'none', cursor: 'pointer' }}
            aria-label="Painel de Atendimento"
          >
            <MessageCircle size={24} />
            <span style={{ marginLeft: '8px', fontSize: '16px' }}>
              Painel de Atendimento
            </span>
          </button>
        )}
        <div
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <img
            src={Icon}
            alt="Logo"
            style={{
              width: '50px',
              height: '50px',
              objectFit: 'contain',
            }}
          />
        </div>
        <div style={{ width: '24px' }}></div>
      </div>

      {currentView === 'chat' ? (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '20px',
              gap: '10px',
              width: '100%',
            }}
          >
            <div
              className="option"
              onClick={() => handleOptionClick('Gerenciar Assinatura')}
              style={{
                backgroundColor: '#242424',
                border: '1px solid #444654',
                padding: '15px',
                borderRadius: '8px',
                cursor: 'pointer',
                textAlign: 'center',
                width: '150px',
              }}
            >
              <span style={{ fontSize: '24px', display: 'block', marginBottom: '5px' }}>💳</span>
              <p style={{ margin: 0 }}>Gerenciar Assinatura</p>
            </div>

            <div
              className="option"
              onClick={() => handleOptionClick('Config WhatsApp')}
              style={{
                backgroundColor: '#242424',
                border: '1px solid #444654',
                padding: '15px',
                borderRadius: '8px',
                cursor: 'pointer',
                textAlign: 'center',
                width: '150px',
              }}
            >
              <img
                src={whatsappLogo}
                alt="WhatsApp Logo"
                style={{ width: '35px', height: '35px', display: 'block', margin: '0 auto 5px' }}
              />
              <p style={{ margin: 0 }}>Configuração do WhatsApp</p>
            </div>

            <div
              className="option"
              onClick={() => handleOptionClick('Disparo de Mensagem')}
              style={{
                backgroundColor: '#242424',
                border: '1px solid #444654',
                padding: '15px',
                borderRadius: '8px',
                cursor: 'pointer',
                textAlign: 'center',
                width: '150px',
              }}
            >
              <span style={{ fontSize: '24px', display: 'block', marginBottom: '5px' }}>📨</span>
              <p style={{ margin: 0 }}>Disparo de Mensagem</p>
            </div>

            <div
              className="option"
              onClick={() => handleOptionClick('Config Bot')}
              style={{
                backgroundColor: '#242424',
                border: '1px solid #444654',
                padding: '15px',
                borderRadius: '8px',
                cursor: 'pointer',
                textAlign: 'center',
                width: '150px',
              }}
            >
              <span style={{ fontSize: '24px', display: 'block', marginBottom: '5px' }}>🤖</span>
              <p style={{ margin: 0 }}>Configuração do Bot</p>
            </div>

            <div
              className="option"
              onClick={() => handleOptionClick('Config Usuário')}
              style={{
                backgroundColor: '#242424',
                border: '1px solid #444654',
                padding: '15px',
                borderRadius: '8px',
                cursor: 'pointer',
                textAlign: 'center',
                width: '150px',
              }}
            >
              <span style={{ fontSize: '24px', display: 'block', marginBottom: '5px' }}>👤</span>
              <p style={{ margin: 0 }}>Configuração do Usuário</p>
            </div>
          </div>

          <div
            style={{
              flexGrow: 1,
              overflowY: 'auto',
              padding: '20px',
              width: '100%',
              maxWidth: '800px',
              backgroundColor: '#242424',
            }}
            id="chat-container"
          >
            {defaultMessageShown && messages.length === 0 && (
              <div style={{ 
                textAlign: 'center', 
                color: '#e9edef',  
                fontSize: '24px',  
                marginBottom: '20px',
                fontFamily: 'Arial, sans-serif',  
                fontWeight: 'bold',
                whiteSpace: 'pre-wrap',
              }}>
                {typedMessage}
              </div>
            )}
            {messages.map((message, index) => (
              <div
                key={index}
                className={`flex mb-4 ${
                  message.isUser ? 'justify-end' : 'justify-start'
                }`}
              >
                <div
                  className={`rounded-lg p-3 max-w-xs ${
                    message.isUser
                      ? 'bg-[#141414] text-right'
                      : 'bg-[#242424] text-left'
                  } relative`}
                  style={{ maxWidth: '100%' }}
                >
                  {typeof message.text === 'string' ? (
                    <p className="text-white">{message.text}</p>
                  ) : (
                    <pre className="text-white">{JSON.stringify(message.text, null, 2)}</pre>
                  )}
                  <span className="text-xs text-gray-300 absolute bottom-1 right-2"></span>
                </div>
              </div>
            ))}

            {isTyping && (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '10px',
                marginBottom: '10px',
                color: '#e9edef',
                fontStyle: 'italic',
              }}>
                <Lightbulb size={20} className="blinking-icon" style={{ marginRight: '8px' }} aria-label="Indicador de digitação" />
                <span>Beny está digitando...</span>
              </div>
            )}

            <div ref={messagesEndRef} />
          </div>

          <div
            className="p-4 bg-[#242424] flex justify-center"
            style={{ width: '100%', maxWidth: '800px' }}
          >
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <button
                onClick={handleAttach}
                className="flex items-center justify-center text-gray-400"
                style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                aria-label="Anexar arquivo"
              >
                <Paperclip size={24} />
              </button>
              <input
                type="file"
                id="file-input"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              <input
                type="text"
                placeholder="Mensagem Beny"
                id="user-input"
                className="flex-1 p-2 rounded bg-[#242424] text-gray-200 placeholder-gray-400 mx-4"
                onKeyPress={handleUserInput}
                aria-label="Mensagem Beny"
              />
              <button
                onClick={handleUserInput}
                className="flex items-center justify-center text-gray-400"
                style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                aria-label="Enviar mensagem"
              >
                <Send size={24} />
              </button>
            </div>
          </div>
        </>
      ) : currentView === 'whatsappConfig' ? (
        <WhatsAppConfig setCurrentView={setCurrentView} />
      ) : currentView === 'botConfig' ? (
        <BotConfig onBack={handleBack} />
      ) : currentView === 'userConfig' ? (
        <UserConfig onBack={handleBack} />
      ) : currentView === 'messageDispatcher' ? (
        <MessageDispatcher onBack={handleBack} />
      ) : (
        <div style={{ color: '#fff', marginTop: '20px' }}>
          Visualização não encontrada
        </div>
      )}
    </div>
  );
};

// Estilos reutilizáveis
const tableHeaderStyle = {
  borderBottom: '2px solid #444654',
  padding: '10px',
  textAlign: 'left',
  fontSize: '16px',
};

const tableRowStyle = {
  borderBottom: '1px solid #444654',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
};

const tableCellStyle = {
  padding: '10px',
  fontSize: '14px',
};

const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
  overflowY: 'auto',
};

const modalContentStyle = {
  backgroundColor: '#242424',
  padding: '0px',
  borderRadius: '12px',
  width: '90%',
  maxWidth: '800px',
  maxHeight: '90vh',
  boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  overflow: 'hidden',
};

const modalHeaderStyle = {
  padding: '20px',
  borderBottom: '1px solid #444654',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const modalBodyStyle = {
  padding: '20px',
  overflowY: 'auto',
  flex: '1 1 auto',
};

const closeButtonStyle = {
  backgroundColor: 'transparent',
  border: 'none',
  color: '#fff',
  cursor: 'pointer',
};

export default ChatSettings;
