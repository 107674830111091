import React, { useState, useEffect, useContext, useRef } from 'react';
import { Plus, Loader2, MessageSquare, User, Trash2 } from 'lucide-react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { CompanyContext } from '../contexts/CompanyContext';
import { Lightbulb } from 'lucide-react';
import WhatsAppProfile from './WhatsAppProfile';
import WhatsAppTemplates from './WhatsAppTemplates';

const WhatsAppConfig = ({ setCurrentView }) => {
  const { selectedCompany } = useContext(CompanyContext);
  const [currentSubView, setCurrentSubView] = useState('main'); // 'main', 'profile', 'templates'

  // Estado para armazenar o usuário autenticado
  const [user, setUser] = useState(null);

  // Estado para armazenar as configurações do WhatsApp
  const [cadastro, setCadastro] = useState({
    nome: '',
    numeroConectado: '',
    limitesMensagens: '',
    statusNumero: '',
    wabaId: '',
    verificacaoEmpresarial: '',
    statusConta: '',
    linhaCredito: '',
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);

  // useRef para armazenar o código do Facebook de forma síncrona
  const fbCodeRef = useRef(null);

  // Função para transformar o status recebido em texto e cor desejados
  const getStatusTextAndColor = (status) => {
    if (status === "CONNECTED") return { text: "Conectado", color: "green" };
    if (status === "DISCONNECTED") return { text: "Desconectado", color: "red" };
    if (status === "PENDING") return { text: "Pendente", color: "yellow" };
    return { text: status || "", color: "#fff" };
  };

  // Atualiza o estado do usuário via onAuthStateChanged
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      }
    });
    return unsubscribe;
  }, []);

  // Função para obter o token do Firebase
  const getFirebaseToken = async () => {
    const auth = getAuth();
    if (auth.currentUser) {
      return await auth.currentUser.getIdToken();
    }
    return new Promise((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(
        auth,
        (user) => {
          if (user) {
            unsubscribe();
            resolve(user.getIdToken());
          }
        },
        (error) => {
          unsubscribe();
          reject(error);
        }
      );
    });
  };

  // Validação simples no frontend antes de iniciar o cadastro
  const iniciarCadastroIncorporado = () => {
    if (!selectedCompany || !selectedCompany.companyId) {
      setMessage({ type: 'error', text: 'Empresa não selecionada. Faça login novamente.' });
      return;
    }
    // Aqui você pode incluir validações adicionais se houver campos obrigatórios no fluxo
    launchWhatsAppSignup();
  };

  // Função para iniciar o cadastro incorporado via SDK do Facebook
  const launchWhatsAppSignup = () => {
    const configId = '1237469174054721'; // Certifique-se de que este valor esteja correto
    if (window.FB) {
      window.FB.login(
        (response) => {
          if (response && response.authResponse) {
            const code = response.authResponse.code || response.code;
            if (code) {
              fbCodeRef.current = code;
            } else {
              setMessage({ type: 'error', text: 'Código de autenticação não encontrado. Tente novamente.' });
            }
          } else {
            setMessage({ type: 'error', text: 'Falha na autenticação. Tente novamente.' });
          }
        },
        {
          config_id: configId,
          response_type: 'code',
          override_default_response_type: true,
          extras: {
            setup: {},
            featureType: '',
            sessionInfoVersion: '3',
          },
        }
      );
    } else {
      setMessage({ type: 'error', text: 'SDK do Facebook não carregado. Tente novamente mais tarde.' });
    }
  };

  // Listener para receber mensagens do fluxo de cadastro incorporado
  useEffect(() => {
    const messageListener = (event) => {
      const allowedOrigins = ['https://www.facebook.com', 'https://web.facebook.com'];
      if (!allowedOrigins.includes(event.origin)) return;
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          if (data.event === 'FINISH') {
            const { phone_number_id } = data.data;
            setTimeout(() => {
              if (!fbCodeRef.current) {
                setMessage({ type: 'error', text: 'Não foi possível registrar: código não encontrado. Tente novamente.' });
                return;
              }
              registrarNumeroNaCloudAPI(fbCodeRef.current, phone_number_id);
            }, 500);
          } else if (data.event === 'CANCEL') {
            const { current_step } = data.data;
            setMessage({ type: 'error', text: `Cadastro cancelado na etapa: ${current_step}` });
          } else if (data.event === 'ERROR') {
            const { error_message } = data.data;
            setMessage({ type: 'error', text: `Erro no cadastro: ${error_message}` });
          }
        }
      } catch (error) {
        console.error('Erro ao processar a mensagem do fluxo:', error);
      }
    };

    window.addEventListener('message', messageListener, false);
    return () => {
      window.removeEventListener('message', messageListener, false);
    };
  }, []);

  const fetchWhatsAppConfig = async () => {
    try {
      setLoading(true);
      const firebaseToken = await getFirebaseToken();
      const companyId = selectedCompany?.companyId;
      if (!companyId) {
        setMessage({ type: 'error', text: 'Empresa não selecionada. Faça login novamente.' });
        setLoading(false);
        return null;
      }
      
      console.log('Buscando configurações do WhatsApp...');
      const response = await fetch(`https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/settings?companyId=${companyId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${firebaseToken}`,
        },
      });
      
      const data = await response.json();
      console.log('Resposta da API:', data);
      
      if (response.ok) {
        if (data.whatsappConfig) {
          console.log('Configurações encontradas:', data.whatsappConfig);
          console.log('Número conectado:', data.whatsappConfig.numeroConectado);
          setCadastro(data.whatsappConfig);
          setDataFetched(true);
          return data.whatsappConfig;
        } else {
          console.log('Nenhuma configuração encontrada');
          setCadastro({
            nome: '',
            numeroConectado: '',
            limitesMensagens: '',
            statusNumero: '',
            wabaId: '',
            verificacaoEmpresarial: '',
            statusConta: '',
            linhaCredito: '',
          });
          setDataFetched(true);
          return null;
        }
      } else {
        console.error('Erro na resposta:', data.error);
        setMessage({ type: 'error', text: data.error || 'Erro ao carregar as configurações.' });
        return null;
      }
    } catch (error) {
      console.error('Erro ao buscar configurações:', error);
      setMessage({ type: 'error', text: 'Ocorreu um erro ao carregar as configurações.' });
      return null;
    } finally {
      setLoading(false);
    }
  };

  const registrarNumeroNaCloudAPI = async (code, phoneNumberId) => {
    try {
      setLoading(true);
      const firebaseToken = await getFirebaseToken();
      const response = await fetch(
        'https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/processar-cadastro',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${firebaseToken}`,
          },
          body: JSON.stringify({
            code: code,
            phoneNumberId: phoneNumberId,
            pin: generatePinForFrontend(),
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        // Tenta buscar as configurações várias vezes com um intervalo
        let attempts = 0;
        const maxAttempts = 3;
        const fetchConfig = async () => {
          const configData = await fetchWhatsAppConfig();
          if (configData && configData.numeroConectado) {
            setCadastro(configData);
            setMessage({
              type: 'success',
              text: `Parabéns! Seu WhatsApp foi adicionado com sucesso. Número: ${configData.numeroConectado}`,
            });
          } else if (attempts < maxAttempts) {
            attempts++;
            setTimeout(fetchConfig, 2000); // Tenta novamente após 2 segundos
          }
        };
        
        await fetchConfig();
      } else {
        setMessage({
          type: 'error',
          text: data.error || 'Falha ao registrar o número na Cloud API.',
        });
      }
    } catch (error) {
      console.error('Erro ao registrar número:', error);
      setMessage({ type: 'error', text: 'Erro ao registrar número.' });
    } finally {
      setLoading(false);
    }
  };

  // Função para gerar um PIN de 6 dígitos no frontend
  const generatePinForFrontend = () => {
    let pin = '';
    for (let i = 0; i < 6; i++) {
      pin += Math.floor(Math.random() * 10);
    }
    return pin;
  };

  useEffect(() => {
    console.log('Usuário autenticado:', user);
    console.log('Empresa selecionada (CompanyContext):', selectedCompany);
    if (user && selectedCompany && selectedCompany.companyId) {
      console.log('Chamando fetchWhatsAppConfig...');
      fetchWhatsAppConfig();
    }
  }, [user, selectedCompany]);

  const handleEditarPerfil = () => {
    setCurrentSubView('profile');
  };

  const handleModelosMensagem = () => {
    setCurrentSubView('templates');
  };

  const handleBack = () => {
    setCurrentSubView('main');
  };

  // Obtém os dados de status transformados (texto, cor e negrito)
  const statusData = getStatusTextAndColor(cadastro.statusNumero);

  const handleDeleteWhatsApp = async () => {
    if (!window.confirm('Tem certeza que deseja excluir esta configuração do WhatsApp? Esta ação não pode ser desfeita.')) {
      return;
    }

    try {
      setLoading(true);
      const firebaseToken = await getFirebaseToken();
      const companyId = selectedCompany?.companyId;

      if (!companyId) {
        setMessage({ type: 'error', text: 'Empresa não selecionada. Faça login novamente.' });
        return;
      }

      const response = await fetch(
        `https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/delete-whatsapp?companyId=${companyId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${firebaseToken}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setMessage({ type: 'success', text: 'Configuração do WhatsApp excluída com sucesso!' });
        // Limpa o estado do cadastro
        setCadastro({
          nome: '',
          numeroConectado: '',
          limitesMensagens: '',
          statusNumero: '',
          wabaId: '',
          verificacaoEmpresarial: '',
          statusConta: '',
          linhaCredito: '',
        });
      } else {
        setMessage({ type: 'error', text: data.error || 'Erro ao excluir a configuração do WhatsApp.' });
      }
    } catch (error) {
      console.error('Erro ao excluir configuração:', error);
      setMessage({ type: 'error', text: 'Ocorreu um erro ao excluir a configuração.' });
    } finally {
      setLoading(false);
    }
  };

  if (currentSubView === 'profile') {
    return <WhatsAppProfile onBack={handleBack} />;
  }

  if (currentSubView === 'templates') {
    return <WhatsAppTemplates onBack={handleBack} />;
  }

  return (
    <div
      style={{
        backgroundColor: '#242424',
        color: '#fff',
        padding: '30px',
        borderRadius: '12px',
        width: '100%',
        maxWidth: '1200px',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        margin: '0 auto',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button
          onClick={iniciarCadastroIncorporado}
          style={{
            backgroundColor: '#242424',
            color: '#e5e7eb',
            padding: '10px 20px',
            border: '1px solid #555',
            borderRadius: '6px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '16px',
            transition: 'background-color 0.3s, border 0.3s',
          }}
          aria-label="Adicionar Configuração"
        >
          <Plus size={20} />
          Adicionar
        </button>
      </div>
      <h2 style={{ textAlign: 'center', fontSize: '16px', margin: '0' }}>
        Conta do WhatsApp
      </h2>
      <form style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
          <div style={{ flex: '1 1 22%', minWidth: '200px' }}>
            <label htmlFor="nome" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
              Nome
            </label>
            <input
              type="text"
              id="nome"
              placeholder={loading && !dataFetched ? 'Carregando...' : 'Nome não disponível'}
              value={cadastro.nome || ''}
              disabled
              style={{
                backgroundColor: '#333',
                color: '#fff',
                padding: '12px',
                borderRadius: '6px',
                border: '1px solid #555',
                width: '100%',
                fontSize: '14px',
                cursor: 'not-allowed',
                boxSizing: 'border-box',
                height: '44px',
              }}
            />
          </div>
          <div style={{ flex: '1 1 22%', minWidth: '200px' }}>
            <label htmlFor="numeroConectado" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
              Número Conectado
            </label>
            <input
              type="tel"
              id="phoneNumber"
              placeholder={loading && !dataFetched ? 'Carregando...' : 'Número não disponível'}
              value={cadastro.numeroConectado || ''}
              disabled
              style={{
                backgroundColor: '#333',
                color: '#fff',
                padding: '12px',
                borderRadius: '6px',
                border: '1px solid #555',
                width: '100%',
                fontSize: '14px',
                cursor: 'not-allowed',
                boxSizing: 'border-box',
                height: '44px',
              }}
            />
          </div>
          <div style={{ flex: '1 1 22%', minWidth: '200px' }}>
            <label htmlFor="limitesMensagens" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
              Limites de Mensagens
            </label>
            <input
              type="text"
              id="limitesMensagens"
              placeholder={loading && !dataFetched ? 'Carregando...' : 'Limite não disponível'}
              value={cadastro.limitesMensagens || "250 clientes/24 horas"}
              disabled
              style={{
                backgroundColor: '#333',
                color: '#fff',
                padding: '12px',
                borderRadius: '6px',
                border: '1px solid #555',
                width: '100%',
                fontSize: '14px',
                cursor: 'not-allowed',
                boxSizing: 'border-box',
                height: '44px',
              }}
            />
          </div>
          <div style={{ flex: '1 1 22%', minWidth: '200px' }}>
            <label htmlFor="statusNumero" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
              Status
            </label>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <input
                type="text"
                id="statusNumero"
                placeholder={loading && !dataFetched ? 'Carregando...' : 'Status não disponível'}
                value={statusData.text}
                disabled
                style={{
                  backgroundColor: '#333',
                  color: statusData.color,
                  fontWeight: 'bold',
                  padding: '12px',
                  borderRadius: '6px',
                  border: '1px solid #555',
                  width: '100%',
                  fontSize: '14px',
                  cursor: 'not-allowed',
                  boxSizing: 'border-box',
                  height: '44px',
                }}
              />
              <button
                style={{
                  backgroundColor: '#333',
                  color: '#ff6b6b',
                  padding: '12px',
                  borderRadius: '6px',
                  border: '1px solid #555',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '44px',
                  transition: 'background-color 0.3s',
                }}
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#444'}
                onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#333'}
                onClick={handleDeleteWhatsApp}
                disabled={loading}
              >
                <Trash2 size={20} />
              </button>
            </div>
          </div>
        </div>
      </form>

      <div style={{ display: 'flex', gap: '20px', justifyContent: 'center', marginTop: '20px' }}>
        <button
          onClick={handleEditarPerfil}
          style={{
            backgroundColor: '#242424',
            color: '#e5e7eb',
            padding: '15px 30px',
            border: '1px solid #555',
            borderRadius: '6px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '16px',
            transition: 'background-color 0.3s, border 0.3s',
          }}
        >
          <User size={20} />
          Editar Perfil
        </button>
        <button
          onClick={handleModelosMensagem}
          style={{
            backgroundColor: '#242424',
            color: '#e5e7eb',
            padding: '15px 30px',
            border: '1px solid #555',
            borderRadius: '6px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '16px',
            transition: 'background-color 0.3s, border 0.3s',
          }}
        >
          <MessageSquare size={20} />
          Modelos de Mensagem
        </button>
      </div>

      {message && (
        <div
          style={{
            padding: '10px',
            backgroundColor: '#242424',
            borderRadius: '6px',
            textAlign: 'center',
          }}
        >
          {message.text}
        </div>
      )}
      {loading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <Lightbulb size={24} className="blinking-icon" aria-label="Carregando" />
        </div>
      )}
    </div>
  );
};

export default WhatsAppConfig;
