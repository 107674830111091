import React, { createContext, useState, useEffect } from 'react';

export const CompanyContext = createContext();

export const CompanyProvider = ({ children }) => {
  const [selectedCompany, setSelectedCompany] = useState(() => {
    const stored = localStorage.getItem('selectedCompany');
    return stored ? JSON.parse(stored) : null;
  });

  const updateSelectedCompany = (company) => {
    setSelectedCompany(company);
    localStorage.setItem('selectedCompany', JSON.stringify(company));
  };

  useEffect(() => {
    if (selectedCompany) {
      localStorage.setItem('selectedCompany', JSON.stringify(selectedCompany));
    }
  }, [selectedCompany]);

  return (
    <CompanyContext.Provider value={{ selectedCompany, updateSelectedCompany }}>
      {children}
    </CompanyContext.Provider>
  );
};
