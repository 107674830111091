import React, { useState, useEffect, useContext } from 'react';
import { Save, Plus, X } from 'lucide-react';
import { getAuth, updateProfile } from 'firebase/auth';
import axios from 'axios';
import { CompanyContext } from '../contexts/CompanyContext';
import { Lightbulb } from 'lucide-react';

const profileDisplayNames = {
  admin: 'Administrador',
  gerente: 'Gerente',
  atendente: 'Atendente',
};

const profileDescriptions = {
  admin: 'Os Administradores têm acesso completo ao sistema, podendo gerenciar todos os usuários e configurações.',
  gerente: 'Os Gerentes podem supervisionar equipes, acessar relatórios e gerenciar tarefas.',
  atendente: 'Os Atendentes podem interagir com os clientes e realizar operações diárias.',
};

const formatLastLogin = (dateString) => {
  // Se a data for 'Nunca' ou 'Indisponível', retorna o valor diretamente
  if (dateString === 'Nunca' || dateString === 'Indisponível') {
    return dateString;
  }
  
  // Converte a string para objeto Date
  const date = new Date(dateString);
  
  // Verifica se a data é válida
  if (isNaN(date.getTime())) {
    return 'Data inválida';
  }
  
  // Cria o formatador com as opções desejadas
  const options = {
    timeZone: 'America/Sao_Paulo',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };
  
  try {
    const formatter = new Intl.DateTimeFormat('pt-BR', options);
    return formatter.format(date);
  } catch (error) {
    console.error('Erro ao formatar data:', error);
    return 'Data inválida';
  }
};

const UserConfig = () => {
  // Obtenha a empresa selecionada via CompanyContext
  const { selectedCompany } = useContext(CompanyContext);
  console.log("UserConfig - selectedCompany:", selectedCompany);

  // Estados para listagem de usuários
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [usersError, setUsersError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  // Estados para cadastro de novos usuários (Admin)
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [newUserName, setNewUserName] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [newUserWhatsApp, setNewUserWhatsApp] = useState('');
  const [newUserProfile, setNewUserProfile] = useState('atendente');
  const [newUserActive, setNewUserActive] = useState('Ativo');
  const [newUserLoading, setNewUserLoading] = useState(false);
  const [newUserMessage, setNewUserMessage] = useState(null);
  const [newUserErrors, setNewUserErrors] = useState({});

  // Estados para edição de um usuário específico
  const [editingUser, setEditingUser] = useState(null);
  const [editName, setEditName] = useState('');
  const [editEmail, setEditEmail] = useState('');
  const [editPassword, setEditPassword] = useState('');
  const [editWhatsApp, setEditWhatsApp] = useState('');
  const [editProfile, setEditProfile] = useState('atendente');
  const [editActive, setEditActive] = useState('Ativo');
  const [editLoading, setEditLoading] = useState(false);
  const [editMessage, setEditMessage] = useState(null);
  const [editUserErrors, setEditUserErrors] = useState({});

  // Estado para simular ser admin (apenas para desenvolvimento)
  const [isAdmin, setIsAdmin] = useState(true);
  const authInstance = getAuth();

  const formatDateToBrazilian = (dateString) => {
    if (!dateString || dateString === 'Nunca' || dateString === 'Indisponível') {
      return dateString;
    }
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Data inválida';
    }
    const options = {
      timeZone: 'America/Sao_Paulo',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };
    const formatter = new Intl.DateTimeFormat('pt-BR', options);
    return formatter.format(date);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      setUsersLoading(true);
      setUsersError(null);
      try {
        const currentUser = authInstance.currentUser;
        if (!currentUser) {
          setUsersError('Usuário não autenticado.');
          setUsersLoading(false);
          return;
        }
        const idToken = await currentUser.getIdToken();
        const config = { headers: { Authorization: `Bearer ${idToken}` } };
        const response = await axios.get(
          `https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/get-users?companyId=${selectedCompany?.companyId}`,
          config
        );
        setUsers(response.data);
      } catch (error) {
        console.error('Erro ao buscar usuários:', error);
        // Extrai a mensagem personalizada enviada pelo backend
        const errorMessage =
          error.response &&
          error.response.data &&
          (error.response.data.message || error.response.data.error)
            ? error.response.data.message || error.response.data.error
            : 'Erro ao buscar usuários. Por favor, tente novamente.';
        setUsersError(errorMessage);
      } finally {
        setUsersLoading(false);
      }
    };
  
    if (isAdmin) {
      fetchUsers();
    } else {
      setUsersError('Acesso negado. Usuário não é administrador.');
      setUsersLoading(false);
    }
  }, [authInstance, isAdmin, selectedCompany]);        

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?#&])[A-Za-z\d@$!%*?#&]{8,}$/;
    return regex.test(password);
  };

  const validatePhoneNumber = (number) => {
    const cleanedNumber = number.replace(/\D/g, '');
    return cleanedNumber.length >= 10 && cleanedNumber.length <= 15;
  };

  const convertStatusToBoolean = (status) => {
    return status === 'Ativo';
  };

  const convertBooleanToStatus = (active) => {
    return active ? 'Ativo' : 'Desativado';
  };

  const handleCreateUser = async () => {
    setNewUserMessage(null);
    setNewUserErrors({});
    const newError = {};
    if (!newUserName.trim()) {
      newError.newUserName = 'Por favor, insira o nome do usuário.';
    }
    if (!newUserEmail.trim()) {
      newError.newUserEmail = 'Por favor, insira o email do usuário.';
    }
    if (newUserPassword.trim() && !validatePassword(newUserPassword)) {
      newError.newUserPassword = 'A senha deve conter pelo menos 8 caracteres, incluindo uma letra maiúscula, uma minúscula, um número e um caractere especial.';
    }
    if (!newUserWhatsApp.trim()) {
      newError.newUserWhatsApp = 'Por favor, insira o número de WhatsApp do usuário.';
    } else if (!validatePhoneNumber(newUserWhatsApp)) {
      newError.newUserWhatsApp = 'Por favor, insira um número de WhatsApp válido.';
    }
    
    // Validação para email duplicado entre os usuários já associados à empresa
    const emailJaCadastrado = users.some(
      (user) => user.email.toLowerCase() === newUserEmail.toLowerCase()
    );
    if (emailJaCadastrado) {
      newError.newUserEmail = 'Este usuário já está associado à empresa.';
    }
    
    if (Object.keys(newError).length > 0) {
      setNewUserErrors(newError);
      return;
    }
    
    setNewUserLoading(true);
    setNewUserMessage(null);
    try {
      const currentUser = authInstance.currentUser;
      if (!currentUser) {
        setNewUserMessage({ type: 'error', text: 'Usuário não autenticado. Por favor, faça login.' });
        setNewUserLoading(false);
        return;
      }
      const idToken = await currentUser.getIdToken();
      const config = { headers: { Authorization: `Bearer ${idToken}` } };
      const payload = {
        displayName: newUserName,
        email: newUserEmail,
        password: newUserPassword || undefined,
        whatsapp: newUserWhatsApp,
        profile: newUserProfile,
        active: convertStatusToBoolean(newUserActive),
        companyId: selectedCompany?.companyId,
      };
      try {
        const createUserResponse = await axios.post(
          'https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/create-user',
          payload,
          config
        );
        if (createUserResponse.status === 201) {
          // Atualiza o displayName do usuário no Firebase Auth
          updateProfile(authInstance.currentUser, { displayName: newUserName })
            .catch(error => console.error("Erro ao atualizar displayName:", error));

          const newUser = {
            uid: createUserResponse.data.uid,
            displayName: newUserName,
            email: newUserEmail,
            profile: newUserProfile,
            whatsapp: newUserWhatsApp,
            active: convertStatusToBoolean(newUserActive),
            lastLoginFormatted: 'Nunca',
            online: false,
          };
          const updatedUsers = [...users, newUser].sort((a, b) =>
            a.displayName.localeCompare(b.displayName)
          );
          setUsers(updatedUsers);
          setNewUserMessage({ type: 'success', text: 'Novo usuário criado com sucesso!' });
          setNewUserName('');
          setNewUserEmail('');
          setNewUserPassword('');
          setNewUserWhatsApp('');
          setNewUserProfile('atendente');
          setNewUserActive('Ativo');
          setShowCreateForm(false);
        } else {
          setNewUserMessage({ type: 'error', text: 'Erro ao criar usuário. Por favor, tente novamente.' });
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.toLowerCase().includes('email já está em uso')
        ) {
          try {
            const addUserResponse = await axios.post(
              'https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/add-user-to-company',
              {
                email: newUserEmail,
                companyId: selectedCompany?.companyId,
                role: newUserProfile,
                active: convertStatusToBoolean(newUserActive),
                whatsapp: newUserWhatsApp,
              },
              config
            );
            if (addUserResponse.status === 200) {
              const newUser = {
                uid: addUserResponse.data.uid,
                displayName: newUserName,
                email: newUserEmail,
                profile: newUserProfile,
                whatsapp: newUserWhatsApp,
                active: convertStatusToBoolean(newUserActive),
                lastLoginFormatted: 'Nunca',
                online: false,
              };
              const updatedUsers = [...users, newUser].sort((a, b) =>
                a.displayName.localeCompare(b.displayName)
              );
              setUsers(updatedUsers);
              setNewUserMessage({ type: 'success', text: 'Usuário associado à empresa com sucesso!' });
              setNewUserName('');
              setNewUserEmail('');
              setNewUserPassword('');
              setNewUserWhatsApp('');
              setNewUserProfile('atendente');
              setNewUserActive('Ativo');
              setShowCreateForm(false);
            } else {
              setNewUserMessage({ type: 'error', text: 'Erro ao associar usuário à empresa. Por favor, tente novamente.' });
            }
          } catch (err) {
            console.error('Erro ao associar usuário à empresa:', err);
            let errorText = 'Erro ao associar usuário à empresa. Por favor, tente novamente.';
            if (err.response && err.response.data && err.response.data.error) {
              errorText = err.response.data.error;
            }
            setNewUserMessage({ type: 'error', text: errorText });
          }
        } else {
          console.error('Erro ao criar novo usuário:', error);
          let errorText = 'Erro ao criar novo usuário. Por favor, tente novamente.';
          if (error.response && error.response.data && error.response.data.error) {
            errorText = error.response.data.error;
          }
          setNewUserMessage({ type: 'error', text: errorText });
        }
      }
    } catch (error) {
      console.error('Erro geral ao criar novo usuário:', error);
      setNewUserMessage({ type: 'error', text: 'Erro ao criar novo usuário. Por favor, tente novamente.' });
    } finally {
      setNewUserLoading(false);
    }
  };

  const startEditingUser = (user) => {
    setEditingUser(user);
    setEditName(user.displayName || '');
    setEditEmail(user.email || '');
    setEditPassword('');
    setEditWhatsApp(user.whatsapp || '');
    setEditProfile(user.profile || 'atendente');
    setEditActive(convertBooleanToStatus(user.active));
    setEditMessage(null);
    setEditUserErrors({});
  };

  const cancelEditing = () => {
    setEditingUser(null);
    setEditName('');
    setEditEmail('');
    setEditPassword('');
    setEditWhatsApp('');
    setEditProfile('atendente');
    setEditActive('Ativo');
    setEditMessage(null);
    setEditUserErrors({});
  };

  const handleUpdateUser = async () => {
    setEditMessage(null);
    setEditUserErrors({});
    const editError = {};
    if (!editName.trim()) {
      editError.editName = 'Por favor, preencha o nome.';
    }
    if (!editEmail.trim()) {
      editError.editEmail = 'Por favor, preencha o email.';
    }
    if (editPassword && !validatePassword(editPassword)) {
      editError.editPassword = 'A senha deve conter pelo menos 8 caracteres, incluindo uma letra maiúscula, uma minúscula, um número e um caractere especial.';
    }
    if (!editWhatsApp.trim()) {
      editError.editWhatsApp = 'Por favor, insira o número de WhatsApp do usuário.';
    } else if (!validatePhoneNumber(editWhatsApp)) {
      editError.editWhatsApp = 'Por favor, insira um número de WhatsApp válido.';
    }
    if (Object.keys(editError).length > 0) {
      setEditUserErrors(editError);
      return;
    }
    setEditLoading(true);
    setEditMessage(null);
    try {
      const currentUser = authInstance.currentUser;
      if (!currentUser) {
        setEditMessage({ type: 'error', text: 'Usuário não autenticado. Por favor, faça login.' });
        setEditLoading(false);
        return;
      }
      const idToken = await currentUser.getIdToken();
      const config = { headers: { Authorization: `Bearer ${idToken}` } };
      const payload = {
        uid: editingUser.uid,
        displayName: editName,
        email: editEmail,
        password: editPassword || undefined,
        whatsapp: editWhatsApp,
        profile: editProfile,
        active: convertStatusToBoolean(editActive),
      };
      const response = await axios.put(
        'https://southamerica-east1-iat-consulting.cloudfunctions.net/App-IAT/update-user',
        payload,
        config
      );
      if (response.status === 200) {
        const updatedUsers = users
          .map((user) =>
            user.uid === editingUser.uid
              ? { ...user, displayName: editName, email: editEmail, profile: editProfile, whatsapp: editWhatsApp, active: convertStatusToBoolean(editActive) }
              : user
          )
          .sort((a, b) => a.displayName.localeCompare(b.displayName));
        setUsers(updatedUsers);
        setEditMessage({ type: 'success', text: 'Usuário atualizado com sucesso!' });
        cancelEditing();
      } else {
        setEditMessage({ type: 'error', text: 'Erro ao atualizar usuário. Por favor, tente novamente.' });
      }
    } catch (error) {
      console.error('Erro ao atualizar usuário:', error);
      let errorText = 'Erro ao atualizar usuário. Por favor, tente novamente.';
      if (error.response && error.response.data && error.response.data.error) {
        errorText = error.response.data.error;
      }
      setEditMessage({ type: 'error', text: errorText });
    } finally {
      setEditLoading(false);
    }
  };

  const filteredUsers = users.filter((user) =>
    user.displayName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div
      style={{
        backgroundColor: '#242424',
        color: '#fff',
        padding: '30px',
        borderRadius: '12px',
        width: '100%',
        maxWidth: '1000px',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div></div>
        <button
          onClick={() => setShowCreateForm(true)}
          style={{
            backgroundColor: '#242424',
            color: '#e5e7eb',
            padding: '10px 20px',
            border: '1px solid #555',
            borderRadius: '6px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '16px',
            transition: 'background-color 0.3s, border 0.3s',
          }}
          aria-label="Adicionar Usuário"
        >
          <Plus size={20} />
          Adicionar
        </button>
      </div>

      <div>
        <h3 style={{ textAlign: 'center', fontSize: '16px' }}>Listagem de Usuários</h3>
      </div>

      <div style={{ textAlign: 'center' }}>
        <input
          type="text"
          placeholder="Buscar usuário..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            backgroundColor: '#333',
            color: '#fff',
            padding: '10px 15px',
            borderRadius: '6px',
            border: '1px solid #555',
            width: '80%',
            maxWidth: '400px',
            fontSize: '16px',
          }}
        />
      </div>

      <div>
        {usersLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <Lightbulb size={24} className="blinking-icon" aria-label="Carregando" />
          </div>
        ) : usersError ? (
          <div style={{ textAlign: 'center', color: '#ff6b6b' }}>{usersError}</div>
        ) : (
          <div>
            {filteredUsers.length === 0 ? (
              <div style={{ textAlign: 'center' }}>Nenhum usuário encontrado.</div>
            ) : (
              <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                <thead>
                  <tr>
                    <th style={tableHeaderStyle}>Usuário</th>
                    <th style={tableHeaderStyle}>Perfil de Acesso</th>
                    <th style={tableHeaderStyle}>Status</th>
                    <th style={tableHeaderStyle}>Último Login</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.map((user) => (
                    <tr
                      key={user.uid}
                      style={tableRowStyle}
                      onClick={() => startEditingUser(user)}
                      onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#3a3a3a')}
                      onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
                      aria-label={`Selecionar usuário ${user.displayName}`}
                    >
                      <td style={tableCellStyle}>{user.displayName || 'Sem Nome'}</td>
                      <td style={tableCellStyle}>{profileDisplayNames[user.profile] || 'Atendente'}</td>
                      <td style={tableCellStyle}>
                        {user.active ? (
                          <span style={{ color: '#4caf50', fontWeight: 'bold' }}>Ativo</span>
                        ) : (
                          <span style={{ color: '#ff6b6b', fontWeight: 'bold' }}>Desativado</span>
                        )}
                      </td>
                      <td style={tableCellStyle}>
                        {user.online ? (
                          <span style={{ color: '#4caf50', fontWeight: 'bold' }}>Online</span>
                        ) : (
                          user.lastLogin ? formatLastLogin(user.lastLogin) : 'Nunca'
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>

      {showCreateForm && (
        <div style={modalOverlayStyle}>
          <div style={modalContentStyle}>
            <div style={modalHeaderStyle}>
              <h2 style={{ fontSize: '18px', margin: 0 }}>Cadastro de Novo Usuário</h2>
              <button
                onClick={() => {
                  setShowCreateForm(false);
                  setNewUserName('');
                  setNewUserEmail('');
                  setNewUserPassword('');
                  setNewUserWhatsApp('');
                  setNewUserProfile('atendente');
                  setNewUserActive('Ativo');
                  setNewUserMessage(null);
                  setNewUserErrors({});
                }}
                style={closeButtonStyle}
                aria-label="Fechar cadastro"
              >
                <X size={20} />
              </button>
            </div>
            <div style={modalBodyStyle}>
              <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', marginBottom: '20px' }}>
                <div style={{ flex: '2', minWidth: '200px' }}>
                  <label htmlFor="new-user-name" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                    Nome
                  </label>
                  <input
                    id="new-user-name"
                    type="text"
                    value={newUserName}
                    onChange={(e) => setNewUserName(e.target.value)}
                    placeholder="Nome do novo usuário"
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '12px',
                      borderRadius: '6px',
                      border: `1px solid ${newUserErrors.newUserName ? '#ff6b6b' : '#555'}`,
                      width: '100%',
                      fontSize: '14px',
                      transition: 'border-color 0.3s',
                    }}
                  />
                  {newUserErrors.newUserName && (
                    <small style={{ color: '#ff6b6b', fontSize: '14px' }}>{newUserErrors.newUserName}</small>
                  )}
                </div>
                <div style={{ flex: '1', minWidth: '200px' }}>
                  <label htmlFor="new-user-whatsapp" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                    WhatsApp
                  </label>
                  <input
                    id="new-user-whatsapp"
                    type="text"
                    value={newUserWhatsApp}
                    onChange={(e) => setNewUserWhatsApp(e.target.value)}
                    placeholder="+5511999999999"
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '12px',
                      borderRadius: '6px',
                      border: `1px solid ${newUserErrors.newUserWhatsApp ? '#ff6b6b' : '#555'}`,
                      width: '100%',
                      fontSize: '14px',
                      transition: 'border-color 0.3s',
                    }}
                  />
                  {newUserErrors.newUserWhatsApp && (
                    <small style={{ color: '#ff6b6b', fontSize: '14px' }}>{newUserErrors.newUserWhatsApp}</small>
                  )}
                </div>
              </div>
              <div style={{ marginBottom: '20px' }}>
                <label htmlFor="new-user-email" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                  Email
                </label>
                <input
                  id="new-user-email"
                  type="email"
                  value={newUserEmail}
                  onChange={(e) => setNewUserEmail(e.target.value)}
                  placeholder="Email do novo usuário"
                  style={{
                    backgroundColor: '#333',
                    color: '#fff',
                    padding: '12px',
                    borderRadius: '6px',
                    border: `1px solid ${newUserErrors.newUserEmail ? '#ff6b6b' : '#555'}`,
                    width: '100%',
                    fontSize: '14px',
                    transition: 'border-color 0.3s',
                  }}
                />
                {newUserErrors.newUserEmail && (
                  <small style={{ color: '#ff6b6b', fontSize: '14px' }}>{newUserErrors.newUserEmail}</small>
                )}
              </div>
              <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', marginBottom: '20px' }}>
                <div style={{ flex: '1 1 200px', minWidth: '200px' }}>
                  <label htmlFor="new-user-profile" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                    Perfil de Acesso
                  </label>
                  <select
                    id="new-user-profile"
                    value={newUserProfile}
                    onChange={(e) => setNewUserProfile(e.target.value)}
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '12px',
                      borderRadius: '6px',
                      border: '1px solid #555',
                      width: '100%',
                      fontSize: '14px',
                    }}
                  >
                    <option value="admin">Administrador</option>
                    <option value="gerente">Gerente</option>
                    <option value="atendente">Atendente</option>
                  </select>
                  {newUserProfile && (
                    <small style={{ color: '#aaa', fontSize: '14px', display: 'block', marginTop: '4px' }}>
                      {profileDescriptions[newUserProfile]}
                    </small>
                  )}
                </div>
                <div style={{ flex: '1 1 200px', minWidth: '200px' }}>
                  <label htmlFor="new-user-password" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                    Senha
                  </label>
                  <input
                    id="new-user-password"
                    type="password"
                    value={newUserPassword}
                    onChange={(e) => setNewUserPassword(e.target.value)}
                    placeholder="Senha do novo usuário (opcional)"
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '12px',
                      borderRadius: '6px',
                      border: `1px solid ${newUserErrors.newUserPassword ? '#ff6b6b' : '#555'}`,
                      width: '100%',
                      fontSize: '14px',
                      transition: 'border-color 0.3s',
                    }}
                  />
                  {newUserErrors.newUserPassword && (
                    <small style={{ color: '#ff6b6b', fontSize: '14px' }}>{newUserErrors.newUserPassword}</small>
                  )}
                  <small style={{ color: '#aaa', fontSize: '14px', display: 'block', marginTop: '4px' }}>
                    Se não informada, o usuário poderá definir sua própria senha no primeiro acesso. Se informada, deve conter pelo menos 8 caracteres, incluindo uma letra maiúscula, uma minúscula, um número e um caractere especial.
                  </small>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <label htmlFor="new-user-active" style={{ fontSize: '14px' }}>
                    Status
                  </label>
                  <select
                    id="new-user-active"
                    value={newUserActive}
                    onChange={(e) => setNewUserActive(e.target.value)}
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '8px 12px',
                      borderRadius: '6px',
                      border: '1px solid #555',
                      fontSize: '14px',
                      width: '150px',
                      height: '40px',
                    }}
                  >
                    <option value="Ativo">Ativo</option>
                    <option value="Desativado">Desativado</option>
                  </select>
                </div>
                <button
                  onClick={handleCreateUser}
                  style={{
                    backgroundColor: '#242424',
                    color: '#e5e7eb',
                    padding: '10px 20px',
                    border: '1px solid #555',
                    borderRadius: '6px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    fontSize: '14px',
                    transition: 'background-color 0.3s, border 0.3s',
                  }}
                  disabled={newUserLoading}
                  aria-label="Cadastrar novo usuário"
                >
                  <Save size={16} />
                  {newUserLoading ? 'Cadastrando...' : 'Cadastrar'}
                </button>
              </div>
              {newUserMessage && (
                <div
                  style={{
                    marginTop: '10px',
                    color: newUserMessage.type === 'error' ? '#ff6b6b' : '#4caf50',
                    fontSize: '14px',
                    textAlign: 'center',
                  }}
                >
                  {newUserMessage.text}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {editingUser && (
        <div style={modalOverlayStyle}>
          <div style={modalContentStyle}>
            <div style={modalHeaderStyle}>
              <h2 style={{ fontSize: '18px', margin: 0 }}>Edição de Usuário</h2>
              <button
                onClick={() => {
                  cancelEditing();
                  setEditName('');
                  setEditEmail('');
                  setEditPassword('');
                  setEditWhatsApp('');
                  setEditProfile('atendente');
                  setEditActive('Ativo');
                  setEditMessage(null);
                  setEditUserErrors({});
                }}
                style={closeButtonStyle}
                aria-label="Fechar edição"
              >
                <X size={20} />
              </button>
            </div>
            <div style={modalBodyStyle}>
              <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', marginBottom: '20px' }}>
                <div style={{ flex: '2', minWidth: '200px' }}>
                  <label htmlFor="edit-user-name" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                    Nome
                  </label>
                  <input
                    id="edit-user-name"
                    type="text"
                    value={editName}
                    onChange={(e) => setEditName(e.target.value)}
                    placeholder="Nome do usuário"
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '12px',
                      borderRadius: '6px',
                      border: `1px solid ${editUserErrors.editName ? '#ff6b6b' : '#555'}`,
                      width: '100%',
                      fontSize: '14px',
                      transition: 'border-color 0.3s',
                    }}
                  />
                  {editUserErrors.editName && (
                    <small style={{ color: '#ff6b6b', fontSize: '14px' }}>{editUserErrors.editName}</small>
                  )}
                </div>
                <div style={{ flex: '1', minWidth: '200px' }}>
                  <label htmlFor="edit-user-whatsapp" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                    WhatsApp
                  </label>
                  <input
                    id="edit-user-whatsapp"
                    type="text"
                    value={editWhatsApp}
                    onChange={(e) => setEditWhatsApp(e.target.value)}
                    placeholder="+5511999999999"
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '12px',
                      borderRadius: '6px',
                      border: `1px solid ${editUserErrors.editWhatsApp ? '#ff6b6b' : '#555'}`,
                      width: '100%',
                      fontSize: '14px',
                      transition: 'border-color 0.3s',
                    }}
                  />
                  {editUserErrors.editWhatsApp && (
                    <small style={{ color: '#ff6b6b', fontSize: '14px' }}>{editUserErrors.editWhatsApp}</small>
                  )}
                </div>
              </div>
              <div style={{ marginBottom: '20px' }}>
                <label htmlFor="edit-user-email" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                  Email
                </label>
                <input
                  id="edit-user-email"
                  type="email"
                  value={editEmail}
                  onChange={(e) => setEditEmail(e.target.value)}
                  placeholder="Email do usuário"
                  style={{
                    backgroundColor: '#333',
                    color: '#fff',
                    padding: '12px',
                    borderRadius: '6px',
                    border: `1px solid ${editUserErrors.editEmail ? '#ff6b6b' : '#555'}`,
                    width: '100%',
                    fontSize: '14px',
                    transition: 'border-color 0.3s',
                  }}
                />
                {editUserErrors.editEmail && (
                  <small style={{ color: '#ff6b6b', fontSize: '14px' }}>{editUserErrors.editEmail}</small>
                )}
              </div>
              <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', marginBottom: '20px' }}>
                <div style={{ flex: '1 1 200px', minWidth: '200px' }}>
                  <label htmlFor="edit-user-profile" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                    Perfil de Acesso
                  </label>
                  <select
                    id="edit-user-profile"
                    value={editProfile}
                    onChange={(e) => setEditProfile(e.target.value)}
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '12px',
                      borderRadius: '6px',
                      border: '1px solid #555',
                      width: '100%',
                      fontSize: '14px',
                    }}
                  >
                    <option value="admin">Administrador</option>
                    <option value="gerente">Gerente</option>
                    <option value="atendente">Atendente</option>
                  </select>
                  {editProfile && (
                    <small style={{ color: '#aaa', fontSize: '14px', display: 'block', marginTop: '4px' }}>
                      {profileDescriptions[editProfile]}
                    </small>
                  )}
                </div>
                <div style={{ flex: '1 1 200px', minWidth: '200px' }}>
                  <label htmlFor="edit-user-password" style={{ display: 'block', marginBottom: '8px', fontSize: '14px' }}>
                    Senha
                  </label>
                  <input
                    id="edit-user-password"
                    type="password"
                    value={editPassword}
                    onChange={(e) => setEditPassword(e.target.value)}
                    placeholder="Nova senha"
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '12px',
                      borderRadius: '6px',
                      border: `1px solid ${editUserErrors.editPassword ? '#ff6b6b' : '#555'}`,
                      width: '100%',
                      fontSize: '14px',
                      transition: 'border-color 0.3s',
                    }}
                  />
                  {editUserErrors.editPassword && (
                    <small style={{ color: '#ff6b6b', fontSize: '14px' }}>{editUserErrors.editPassword}</small>
                  )}
                  <small style={{ color: '#aaa', fontSize: '14px', display: 'block', marginTop: '4px' }}>
                    A senha deve ter no mínimo 8 caracteres, com letra maiúscula, minúscula, número e caractere especial.
                  </small>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <label htmlFor="edit-user-active" style={{ fontSize: '14px' }}>
                    Status
                  </label>
                  <select
                    id="edit-user-active"
                    value={editActive}
                    onChange={(e) => setEditActive(e.target.value)}
                    style={{
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '8px 12px',
                      borderRadius: '6px',
                      border: '1px solid #555',
                      fontSize: '14px',
                      width: '150px',
                      height: '40px',
                    }}
                  >
                    <option value="Ativo">Ativo</option>
                    <option value="Desativado">Desativado</option>
                  </select>
                </div>
                <button
                  onClick={handleUpdateUser}
                  style={{
                    backgroundColor: '#242424',
                    padding: '10px 20px',
                    color: '#e5e7eb',
                    borderRadius: '6px',
                    cursor: 'pointer',
                    border: '1px solid #555',
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    transition: 'background-color 0.3s, border 0.3s',
                  }}
                  disabled={editLoading}
                  aria-label="Atualizar usuário"
                >
                  <Save size={16} />
                  {editLoading ? 'Atualizando...' : 'Atualizar'}
                </button>
              </div>
              {editMessage && (
                <div
                  style={{
                    marginTop: '10px',
                    color: editMessage.type === 'error' ? '#ff6b6b' : '#4caf50',
                    fontSize: '14px',
                    textAlign: 'center',
                  }}
                >
                  {editMessage.text}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const tableHeaderStyle = {
  borderBottom: '2px solid #444654',
  padding: '10px',
  textAlign: 'left',
  fontSize: '16px',
};

const tableRowStyle = {
  borderBottom: '1px solid #444654',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
};

const tableCellStyle = {
  padding: '10px',
  fontSize: '14px',
};

const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
  overflowY: 'auto',
};

const modalContentStyle = {
  backgroundColor: '#242424',
  padding: '0px',
  borderRadius: '12px',
  width: '90%',
  maxWidth: '800px',
  maxHeight: '90vh',
  boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  overflow: 'hidden',
};

const modalHeaderStyle = {
  padding: '20px',
  borderBottom: '1px solid #444654',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const modalBodyStyle = {
  padding: '20px',
  overflowY: 'auto',
  flex: '1 1 auto',
};

const closeButtonStyle = {
  backgroundColor: 'transparent',
  border: 'none',
  color: '#fff',
  cursor: 'pointer',
};

export default UserConfig;