import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ChatInterface from './components/ChatInterface';
import ChatSettings from './components/ChatSettings';
import LoginScreen from './components/LoginScreen';
import RegisterScreen from './components/RegisterScreen';
import { CompanyProvider } from './contexts/CompanyContext';
import './App.css';

function App() {
  return (
    <CompanyProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/register" element={<RegisterScreen />} />
          <Route path="/chat" element={<ChatInterface />} />
          <Route path="/settings" element={<ChatSettings />} />
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </Router>
    </CompanyProvider>
  );
}

export default App;
